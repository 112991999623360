@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.container.hl_custome_container {
    max-width: 1450px !important;
    width: 100%;
}
body{
	overflow-x: hidden !important;
}
p{
 font-family: 'Montserrat', sans-serif !important;
}
.hl_return_policy_form {
	border: 1px solid #ccc;
	padding: 50px 30px;
}
.rcs_hero_img button {	
	font-size: 14px;
	border-radius: 0;
	padding: 10px 30px;
	font-weight: 400;
	margin: 0;
	font-family: 'Montserrat', sans-serif;
		background-color: transparent !important;
		color: #fff !important;
		border: 1px solid #fff !important;
		transition: all .4s ease-in-out !important;
	}

	.rcs_hero_img button:active,
	.rcs_hero_img button:focus,
	.rcs_hero_img button:hover {
		background-color: var(--primary)!important;
		color: #fff!important;
		border: 1px solid var(--primary)!important;
	}
.rcs_hero_img p {
	font-size: 16px;
	font-weight: 4 00;
	padding: 30px 0 33px;
	letter-spacing: 0px;
	margin: 0;
	text-transform: capitalize;
	font-family: 'Montserrat', sans-serif;
}

/* hala londan Collection Style */
.hl_collection_section {
	padding: 25px 0;
	margin-top: 0;
}
.hl_Collectiom_list ul li {
	display: inline-block !important;
	width: 20%;
	margin: 0 auto;
}
.hl_collection_img_box img {
	width: 100%;
	cursor: pointer;
}
.hl_collection_img_box {
	margin: 0 12.5px;
}
.hl_collection_slider_mobileview .slick-dots li button::before {
	font-size: 12px;
	color: #0A1857 !important;
}
.hl_best_seller_slider .slick-dots li button::before {
	font-size: 12px;
	color: #0A1857 !important;
}

.ddov_btn_gray {
    background-color: #0A1857;
    border: 1px solid #0A1857;
    border-radius: 0;
    color: #fff;
    font-size: 18px;
    line-height: 30px;
    padding: 10px 30px;
    transition: all .4s ease-in-out;
}
.ddov_btn_gray svg{
    color: #fff;
    transition: all .4s ease-in-out;
    transform: 0;
    font-size: 22px;
    margin-left: 10px;
}
.ddov_btn_gray:hover svg{
    transform: translate(10px);
    transition: all .4s ease-in-out;
}
.ddov_btn_outline, .ddov_btn_gray, .hl_text_or p{
    /* font-family: 'Montserrat', sans-serif !important; */
    font-weight: 500;
}
.ddov_btn_outline {
    background-color: #fff;
    border: 1px solid #0A1857;
    border-radius: 0;
    color: #0A1857;
    font-size: 14px;
    line-height: 30px;
    padding: 10px 30px;
    transition: all .4s ease-in-out;
	text-transform: uppercase;
   
}
.ddov_btn_outline:hover svg{
    color: #fff;
}
.ddov_btn_gray:hover svg{
    color: #0A1857;
}
.ddov_btn_outline:hover,.ddov_btn_outline:active, .ddov_btn_outline:focus{
    background-color: #0A1857;
    border: 1px solid #0A1857;
    border-radius: 0;
    color: #fff;
    box-shadow: none;
    outline: 0;
}
.ddov_btn_gray:hover,.ddov_btn_gray:active, .ddov_btn_gray:focus{
    background-color: transparent;
    border: 1px solid #0A1857;
    border-radius: 0;
    color: #0A1857;
    box-shadow: none;
    outline: 0;
}
.ddov_btn_outline svg{
    color: #0A1857;
    transition: all .4s ease-in-out;
    transform: 0;
    font-size: 22px;
    margin-left: 10px;
}
.ddov_btn_outline:hover svg{
    transform: translate(10px);
    transition: all .4s ease-in-out;
}
.hl_text_or p {
	font-size: 18px;
	font-weight: 600;
	color: #ccc;
	margin-bottom: 0;
	padding-bottom: 0;
	text-transform: uppercase;
}
.hl_ring_builder_btn_box {
	display: flex;
	gap: 15px;
	justify-content: left;
	align-items: center;
	/* flex-wrap: wrap; */
}

.hl_ring_builder_btn_box button {
    font-size: 14px;
    padding: 8px 20px;
    min-width: 230px;
	text-transform: uppercase;
}

.ddov_eng_ring_text_box h5 {
	font-weight: 500;
	font-size: 22px;
	line-height: 25px;
	color: #000000;
	margin-bottom: 10px;
}
.ddov_eng_ring_text_box h3 {
	font-weight: 400;
	font-size: 35px;
	line-height: 50px;
	color: #000000;
	margin-bottom: 15px;
}
/* shop diamond by shap  */
.hl_shap_img_box {
    text-align: center;
    cursor: pointer;
    border-radius: 6px;
    padding: 8px 0;
}
.hl_diamod_shap ul li {
	display: inline-block;
	width: 10%;
}
.hl_shap_img_box p {
	font-size: 16px;
	font-weight: 400;
	color: #000;
	padding: 10px 0;
	margin-bottom: 0;
}
.hl_shap_img_box:hover {
    box-shadow: 0 0 20px #cbccd373;
}
.hl_shap_img_box img {
	margin: 0 auto;
}
/* shop slider style */
.hl_diamond_shop_by_shap_slider .slick-prev, .hl_diamond_shop_by_shap_slider .slick-next {
	width: 50px;
	height: auto;
	z-index: 99;
}
.hl_diamond_shop_by_shap_slider .slick-prev {
	left: 0;
}
.hl_diamond_shop_by_shap_slider .slick-next {
	right: 0;
}
/* bacome an export style */
.hl_become_An_expert_section {
	padding: 50px 0;
	/* border-top: 1px solid #0a185721; */
}
.hl_expert_text_img_box {
	text-align: center;
    font-family: 'Montserrat', sans-serif !important;  
    
}
.hl_expert_text_img_box h6 {
	font-size: 22px;
	font-family: 'Montserrat', sans-serif !important;
	line-height: 30px;
	font-weight: 500;
}
.hl_expert_text_img_box p {
	font-size: 16px;
	line-height: 25px;
	font-weight: 400;
	width: 75%;
	margin: 16px auto;
}
.hl_expert_text_img_box img {
	width: 100%;
	height: auto;
	margin-bottom: 20px;
}
.hl_btn_outline {
	font-size: 15px;
	color: #0A1857;
	border:1px solid  #0A1857;
	padding: 10px 40px;
	background-color: transparent;
	text-transform: uppercase;
	transition:  all .4s ease-in-out;
	font-weight: 400;
}
.hl_btn_outline:hover {
	transition:  all .4s ease-in-out;
	border-color: #0A1857;
	background-color: #0A1857;
	color: #fff;
}
.hl_btn_outline_bg {
	font-size: 15px;
	color: #fff;
	background-color: #0A1857;
	border:1px solid  #0A1857;
	padding: 10px 40px;
	text-transform: uppercase;
	transition:  all .4s ease-in-out;
	font-weight: 400;
	min-width: 180px;
}
.hl_btn_outline_bg:hover {
	color: #0A1857;
	background-color: transparent;
	border:1px solid  #0A1857;
	transition:  all .4s ease-in-out;
}
.ddove_review_user {
    MARGIN-TOP: 15PX;
    padding-left: 5%;
}
.ddov_eng_ring_text_box p {
	font-size: 14px;
	color: #000;
}
.hl_text_or p {
	color: #ccc;
	font-size: 16px;
}
.hl_goole_user_img {
	width: 50px;
	height: 50px;
	margin-right: 15px;
	border-radius: 50%;
}
.rcs_customer_review_slide_item{
    position: relative;
}
.rcs_customer_review_slide_item:before {
    background-color: #fff;
    bottom: -5px;
    box-shadow: 2px 0 20px 1px #ccc;
    content: "";
    height: 15px;
    left: 25px;
    position: absolute;
    -webkit-transform: rotate(-225deg);
    transform: rotate(-225deg);
    width: 20px;
    z-index: -1;
}
.ddove_review_user h6 {
	font-size: 16px;
	font-weight: 600;
	font-family: 'Montserrat', sans-serif !important;
	margin-bottom: 0;
}
/* best seller style */
.hl_bestseller_img_text_box {
	text-align: center;
	padding: 20px;
	cursor: pointer;
}

.hl_bestseller_img_box:hover {
    box-shadow: 0 0 20px #ccc;
    background-color: #fff;
}
.hl_bestseller_img_box img {
	margin: 0 auto;
}
.hl_bestseller_img_text_box p {
	font-size: 20px;
	color: #000;
	font-weight: 400;
	line-height: 25px;
	margin: 10px auto 20px;
}
.hl_bestseller_img_text_box h6 {
	font-size: 20px;
	color: #0A1857;
	font-weight: 600;
	line-height: 25px;
	margin: 10px auto;
    font-family: 'Montserrat', sans-serif !important;
}
.hl_bestseller_img_box {
	padding: 15px;
}

.hl_best_seller_slider .slick-prev, .hl_best_seller_slider .slick-next {
	width: 40px !important;
	height: 40px !important;
    z-index: 99;
}
.hl_bestseller_section {
	padding: 50px 0;
}
/* 360 video style */
.hl_hq_video_section{
    padding: 80px 0;
    background-color: #0A1857;
}
.hl_btn_outline_white {
	font-size: 14px;
	color: #fff;
	border: 1px solid #fff;
	padding: 10px 40px;
	background-color: transparent;
	text-transform: uppercase;
	transition: all .4s ease-in-out;
}
.hl_btn_outline_white:hover{
	background-color: #fff;
	color: #0A1857;
	border-color: #0A1857;
	transition: all .4s ease-in-out;
}
.hl_btn_outline_white_bg{
	font-size: 14px;
	color: #0A1857;
	border: 1px solid #fff;
	padding: 10px 40px;
	background-color: #fff;
	text-transform: uppercase;
	transition: all .4s ease-in-out;
}
.hl_btn_outline_white_bg:hover{
  border-color: #fff;
  color: #fff;
  background-color: transparent;
  transition: all .4s ease-in-out;
}
.hl_video_two_btn {
	display: flex;
	justify-content: start;
	align-items: center;
	gap: 20px;
}
.hl_hq_text_box h6 {
	color: #fff;
	font-size: 20px;
	line-height: 35px;
	margin-bottom: 0;
}
.hl_hq_text_box p {
	text-align:left ;
	font-size: 16px;
	font-weight: 400;
	line-height: 25px;
	width: 85%;
}
.hl_hq_text_box h2 {
	font-size: 35px;
	font-weight: 600;
	color: #fff;
	line-height: 55px;
}
.rcs_customer_say_section .hl_diamond_headings {
	text-align: left;
}
.rcs_virtual_appointment_img:hover img {
	transform: scale(1.1);
	transition: all .5s ease-in-out;
}
.rcs_virtual_appointment_img img {
	transition: all .5s ease-in-out;
}
.rcs_virtual_appointment_img {
	width: 100%;
	overflow: hidden;
}

/* shop by shap style */
section.rcs_ring_style_section {
	padding-top: 50px;
	padding-bottom: 50px;
	background-color: #F2F5FF;
}

.hl_diamond_headings h2 {
	font-weight: 400;
	font-size: 35px;
	color: #000;
	line-height: 35px;
	margin-bottom: 20px;
	font-family: 'Playfair Display', serif !important;
	text-transform: capitalize;
}
.hl_diamond_headings p {
	font-size: 16px;
	color: #000;
	font-weight: 400;
	/* font-family: 'Montserrat', sans-serif; */
	/* padding-bottom: 20px; */
}


.heading-wrapper h2 {
	font-family: var(--primary-medium);
	color: var(--primary);
	font-size: 40px;
	line-height: 36px;
	margin-bottom: 15px;
}
/* ring style  */
.rcs_ringstyle img {
	width: 200px;
	height: 200px;
	margin: 0 auto;
	display: block;
	cursor: pointer;
}
.rcs_ringstyle {
	text-align: center;
}
.rcs_ringstyle_content {
	width: 94% !important;
	background-color: #f7f7f7;
	padding: 10px;
	border-radius: 5px;
	box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
   
}
.rcs_ringstyle_content h2 {
	margin: 0;
	cursor: pointer;
}
.rcs_ringstyle_content_box_img img {
    width: 100%;
    height: 100%;
}
.rcs_ringstyle_content_box_img {
    width: 180px;
    /* height: 200px; */
    margin: 0 auto;
    display: block;
    cursor: pointer;
    position: relative;
    z-index: 99;
    margin-top: 50px;
}
/* .rcs_ringstyle_content_box {
    margin: 10px 10px 30px 10px;
    padding: 10px;
    padding-bottom: 30px;
    width: 70% !important;
    margin-left: 0;
} */
.hl_diamond_headings {
    text-align: center;
    text-transform: capitalize;
    color: #000;
}
.rcs_ringstyle.rcs_ringstyle_v2 {
    padding: 50px 0px;
}
.rcs_ringstyle {
    text-align: center;
}
.rcs_ringstyle.rcs_ringstyle_v2 .slick-arrow.slick-next {
    width: 50px;
    height: auto;
    right: 39%;
    z-index: 99;
    top: auto;
    bottom: -10px;
}
.rcs_ringstyle.rcs_ringstyle_v2 .slick-arrow.slick-prev {
    width: 50px;
    height: auto;
    left: 39%;
    z-index: 99;
    top: auto;
    bottom: -10px;
}
.rcs_custom_home_container.container {
    max-width: 1750px;
}
.rcs_ringstyle_content_box_img img {
    width: 100%;
    height: 100%;
}
.rcs_ringstyle img {
	width: 200px;
	height: 200px;
	margin: 0 auto;
	display: block;
	cursor: pointer;
	object-fit: contain;
	transition: all .3s ease-in-out;
}
.rcs_ringstyle_content_box h2 {
	display: none !important;
	font-size: 12px;
	color: #000;
	font-family: 'Montserrat', sans-serif !important;
	font-weight: 400;
}
.rcs_ringstyle.rcs_ringstyle_v2 .slick-slide.slick-active.slick-center.slick-current .rcs_ringstyle_content_box h2{
    display: block !important;
}

.rcs_ringstyle.rcs_ringstyle_v2 ul.slick-dots li.slick-active button:before {
    font-size: 12px;
    border: 1px solid black;
    border-radius: 50%;
}

.rcs_ringstyle.rcs_ringstyle_v2 ul.slick-dots li button:before {
    font-size: 12px;
}

.rcs_ringstyle.rcs_ringstyle_v2 .slick-arrow.slick-prev::before{
    display: none;
}

.rcs_ringstyle.rcs_ringstyle_v2 .slick-arrow.slick-next::before{
    display: none;
}



.rcs_ringstyle.rcs_ringstyle_v2 .slick-slide.slick-active.slick-center.slick-current.slick-center .rcs_ringstyle_content_box {
	transform: scale(1.6);
}
.rcs_ringstyle_content_box {
    margin: 10px 10px 30px 10px;
    padding: 10px;
    padding-bottom: 30px;
    /* width: 70% !important; */
    /* margin-left: 0; */
}
.rcs_ringstyle.rcs_ringstyle_v2 ul.slick-dots {
    bottom: -40px;
}
/* signup style */
section.rcs_signup_to_section {
    background: #F2F5FF;
    padding: 40px 0;
}
.rcs_signup_to_left_main {
    width: 80%;
    margin: auto;
}
.hl_diamond_headings p {
    font-size: 16px;
    color: #000;
    font-weight: 400;
    /* font-family: 'Montserrat', sans-serif; */
    /* padding-bottom: 20px; */
}
.hl_diamond_headings h2 {
    font-weight: 400;
    font-size: 35px;
    color: #000;
    line-height: 35px;
    margin-bottom: 20px;
}

.rcs_signup_to_input_field .input-group button {
    background: #0A1857;
    color: white;
    border-radius: 0;
    border: none;
    padding: 5px 40px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
}
.rcs_signup_to_input_field .input-group input {
    border-radius: 0;
    height: 50px;
    border-color: #000000;
    border: 2px solid #fff;
    box-shadow: none;
}
/* virtual appointment style */
section.rcs_color_virtual_section1 {
    padding: 70px 0 50px;
}
.rcs_virtual_appointment_row {
    align-items: center;
    margin: 0;
}
.rcs_virtual_appointment_text {
    text-align: left;
    width: 85%;
}
.rcs_virtual_appointment_text h2 {
    font-weight: 400;
    text-transform: capitalize;
    font-size: 35px;
}
.rcs_virtual_appointment_text p {
    font-size: 16px;
    margin: auto;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
}
.rcs_virtual_appointment_img img {
    width: 100%;
}

/* stude builder style */
.rcs_studs_builder {
    background: url("../../Assets/images/hala-london-img/stud-builder-banner.jpg") ;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 380px;
    margin: 60px auto;
    /* padding: 6rem 0; */
	display: flex;
	align-items: center;
	justify-content: end;
}
.hl_best_seller_slider .slick-dots li button:before, .hl_collection_slider_mobileview .slick-dots li button:before {
    color: #0a1857!important;
    font-size: 12px;
}
.rcs_studs_builder .ddov_eng_ring_text_box {
	margin-right: 15%;
}

/* Responsive style style */
@media(min-width:320px) and (max-width:767px){
	.rcs_virtual_appointment_img img {
		width: 100%;
		margin-bottom: 20px;
	}
	.rcs_ringstyle_content_box {
		/* margin: 10px 10px 30px 10px; */
		padding: 10px;
		padding-bottom: 30px;
		margin: 10px auto 30px !important;
	}
	.hl_new_login_page_section {
		padding: 30px 0;
	}
	.ddov_eng_ring_text_box {
		text-align: center;
		margin: 20px auto 10px;
	}
	.rcs_studs_builder {
		background: url("../../Assets//images//hala-london-img/stud-builder-banner-mobile.jpg");
		background-repeat: no-repeat;
		width: 100%;
		background-size: cover;
		background-position: 100% 100%;
		height: 310px;
		padding: 15px;
		height: 310px;
    padding: 15px;
    justify-content: center;
    vertical-align: middle;
    align-items: end;
	margin: 20px auto;
	position: relative;
	z-index: 1;
	}
	.rcs_studs_builder::before {
		position: absolute;
		background-color: #efefef54;
		width: 100%;
		height: 100%;
		content: '';
		z-index: -1;
	}
	.rcs_studs_builder .ddov_eng_ring_text_box {
		margin-right: 0;
	}
	.hl_expert_text_img_box p, .rcs_virtual_appointment_text p {
		font-size: 14px;
		line-height: 22px;
		margin: 10px auto;
		width: 100%;
	}
	.rcs_signup_to_input_field .input-group input {
		height: 40px;
	}
	.rcs_signup_to_input_field .input-group input::placeholder{
		font-size: 12px;
	}
	.rcs_signup_to_left_main {
		width: 100%;
		margin: auto;
	}
	.rcs_signup_to_input_field .input-group button {
		padding: 5px 18px;
		font-size: 13px;
	}
	.rcs_virtual_appointment_text h2 {
		font-size: 25px;
		text-align: center;
	}
	.mobile_align_end{
		align-items: end !important;
	}
	.rcs_virtual_appointment_text {
		text-align: center;
		width: 100%;
		margin-bottom: 30px;
	}
	.rcs_hero_img button {
		font-size: 12px;
		padding: 8px 30px;
	}
	.hl_ring_builder_btn_box button {
		font-size: 14px;
	}
	.rcs_ringstyle.rcs_ringstyle_v2 .slick-arrow.slick-prev {
		left: 0;
	}
	.rcs_ringstyle.rcs_ringstyle_v2 .slick-arrow.slick-next {
		right: 0;
	}
	.rcs_ringstyle.rcs_ringstyle_v2 .slick-arrow.slick-prev, .rcs_ringstyle.rcs_ringstyle_v2 .slick-arrow.slick-next {
		bottom: 5px;
	}
	.rcs_ringstyle img {
		width: 150px;
		height: 150px;
	}
	.hl_shap_img_box img {
		height: 100px;
		margin: 0 auto;
		object-fit: contain;
		width: 100px;
	}
	.rcs_hero_img p {
		font-size: 14px;
		padding: 0px 0 15px;
	}
	.hl_ring_builder_img video {
		height: 300px !important;
	}
	.hl_return_policy_form {
		padding: 30px 15px;
	}
	.hl_hq_video_box video {
		height: 200px !important;
	}
	.hl_bestseller_img_box img {
		margin: 0 auto;
		width: 150px !important;
	}
	.hl_bestseller_img_text_box {
		padding: 0 0 20px 0;
	}
	.hl_bestseller_img_box {
		padding: 0;
	}
	.hl_btn_outline_white {
		width: 100%;
		font-size: 14px;
	}
	.hl_btn_outline_white_bg {
		font-size: 14px;
		width: 100%;
		margin-top: 20px;
	}
	.hl_video_two_btn {
		display: block;
	}
	.hl_hq_video_section {
		padding: 50px 0;
	}

	.hl_hq_text_box p {
		width: 100%;
		text-align: center !important;
		font-size: 14px;
		line-height: 22px;
	}
	.hl_hq_text_box {
		text-align: center;
		margin: 30px auto 0;
	}
	.hl_hq_text_box h2 {
		font-size: 24px;
		line-height: 40px;
		text-align: center !important;
	}
	.hl_btn_outline {
		font-size: 13px;
		padding: 8px 30px;
	}
	.hl_become_An_expert_section {
		padding: 40px 0 10px;
		border-top: 1px solid #0a185721;
	}
	.hl_guild_text_boxx {
		margin-bottom: 30px;
	}
	.hl_best_seller_slider .slick-dots {
		bottom: 40px;
	}
	.hl_bestseller_img_text_box p {
		font-size: 16px;
	}
	.hl_bestseller_img_text_box h6 {
		font-size: 16px;
		line-height: 15px;
		margin: 5px auto;
	}
	.rcs_customer_say_section .hl_diamond_headings {
		text-align: center !important
	}
	.hl_diamond_headings h2 {
		font-size: 24px;
		line-height: 25px;
		margin-bottom: 15px;
	}
    .hl_collection_section {
		margin-top: 0;
        padding: 30px 0;
    }
	.rcs_ringbuilder_home {
		margin-top: 0;
	}
    .hl_collection_img_box img {
        margin-bottom: 10px;
    }
    .hl_ring_builder_btn_box {
        display: block;
        justify-content: center;
    }
    .hl_text_or p {
        display: none;
    }
    .ddov_btn_gray,.ddov_btn_outline {
        font-size: 16px;
        line-height: 25px;
        width: 100%;
        margin-bottom: 15px;
    }
    .ddov_eng_ring_text_box {
        text-align: center;
    }
    .ddov_eng_ring_text_box h5 {
        font-size: 20px;
    }
    .ddov_eng_ring_text_box h3 {
        font-size: 30px;
        line-height: 30px;
    }
	section.rcs_signup_to_section {
		background: #F2F5FF;
		padding: 30px 0 20px;
		margin-top: 20px !important;
	}
}
@media(min-width:768px) and (max-width:991px){
	.hl_margin_bottom_box{
		margin-bottom: 30px;
	}
	.rcs_signup_to_input_field .input-group button {
		padding: 5px 25px;
		font-size: 14px;
	}
	.rcs_virtual_appointment_text h2 {
		font-size: 30px;
	}
	.rcs_signup_to_input_field .input-group input {
		height: 40px;
	}
	.rcs_signup_to_left_main {
		width: 100%;
		margin: auto;
	}
	.hl_collection_section {
		padding: 0 0 5px;
		margin-top: 0;
	}
	.rcs_studs_builder .hl_ring_builder_btn_box {
		justify-content: left;
	}
	.hl_ring_builder_btn_box {
		justify-content: center;
	}
	.hl_ring_builder_btn_box button {
		font-size: 14px;
	}
	.rcs_studs_builder .ddov_eng_ring_text_box {
		margin-right: 0;
		width: 45%;
	}
	.rcs_studs_builder {
		height: 230px;
		margin: 40px auto 0;
	}
	.rcs_hero_slider {
		min-height: 300px !important;
	}
	.rcs_hero_img h1 {
		text-align: left !important;
	}
	.hl_ring_builder_img video {
		height: 400px !important;
	}
	.rcs_hero_img p {
		font-size: 14px;
		text-align: left;
		padding: 5px 0 20px;
		width: 60%;
	}
	.hl_return_policy_form {
		border: 1px solid #ccc;
		padding: 40px 20px;
	}


.hl_ring_builder_img img {
	width: 70%;
	margin: 0 auto;
}
.hl_ring_builder_img {
	text-align: center;
}
section.rcs_color_virtual_section1 {
	padding: 40px 0 20px;
}
section.rcs_customer_say_section {
	padding-bottom: 10px;
}
.ddove_review_user {
	padding-left: 7%;
}
.hl_goole_user_img {
	width: 40px;
	height: 40px;
	margin-right: 10px;
}
.ddove_review_user p {
	font-size: 12px;
}
section.rcs_signup_to_section {
	padding: 35px 0;
}
.rcs_signup_to_input_field {
	margin-top: 20px;
}
.rcs_virtual_appointment_img img {
	width: 100%;
	margin: 30px auto;
}
.rcs_virtual_appointment_text {
	text-align: center;
	width: 100%;
	margin: 0 auto;
}
.rcs_virtual_appointment_text p {
	font-size: 14px;
}
.hl_expert_text_img_box h6 {
	font-size: 18px;
	line-height: 20px;
	margin-bottom: 0;
}
.hl_expert_text_img_box p {
	font-size: 14px;
	line-height: 22px;
	width: 80%;
	margin: 13px auto;
}
.hl_become_An_expert_section {
	padding: 40px 0;
}
.hl_btn_outline {
	font-size: 14px;
	padding: 8px 40px;
}
.hl_best_seller_slider .slick-prev, .hl_best_seller_slider .slick-next ,.rcs_customer_say_inner .slick-prev,.rcs_customer_say_inner .slick-next {
	width: 30px !important;
height: 30px !important;
}
.rcs_customer_say_inner .slick-prev {
	left: 82% !important;
}
.hl_best_seller_slider .slick-prev{
	left: 0;
}
.hl_best_seller_slider .slick-next{
	right: 0;
}
.hl_bestseller_img_box img {
	width: 70%;
}
.hl_bestseller_img_text_box h6 {
	font-size: 14px;
	line-height: 22px;
	margin: 5px auto;
}
.hl_bestseller_img_text_box p {
	font-size: 14px;
	line-height: 20px;
	margin: 10px auto 10px;
}
.hl_hq_video_box {
	text-align: center;
}
.hl_hq_video_box img {
	width: 40%;
	margin: 20px auto !important;
}
.hl_hq_video_section {
	padding: 50px 0;
}
.hl_hq_text_box h6 {
	font-size: 17px;
	line-height: 30px;
	text-align: center;
}
.hl_hq_text_box h2 {
	font-size: 30px;
	font-weight: 600;
	line-height: 40px;
	text-align: center !important;
}
.hl_video_two_btn {
	justify-content: center;
}
.hl_hq_text_box {
	margin: 20px auto 0;
}
.hl_hq_text_box p {
	font-size: 14px;
	line-height: 22px;
	width: 90%;
	margin: 0 auto 20px;
	text-align: center;
}
.hl_collection_img_box {
	margin: 0 7.5px;
}
.rcs_ringbuilder_home {
	padding: 0 20px;
}
.ddov_btn_gray, .ddov_btn_outline {
	font-size: 14px;
	line-height: 25px;
	padding: 8px 30px;
}
.ddov_eng_ring_text_box h5 {
	font-size: 18px;
	line-height: 20px;
}
.ddov_eng_ring_text_box h3 {
	font-size: 30px;
	line-height: 25px;
	margin-bottom: 15px;
}
.hl_diamond_headings h2 {
	font-size: 30px;
	line-height: 25px;
	margin-bottom: 15px;
}
.hl_diamond_headings p {
	font-size: 14px;
	padding-bottom: 0px;
	margin-bottom: 10px;
}
.hl_shap_img_box img {
	width: 65px;
	height: 65px;
}
.hl_shap_img_box {
	padding: 4px 0;
}
.hl_shap_img_box p {
	font-size: 14px;
	padding: 5px 0;
}
section.rcs_ring_style_section {
	padding-top: 40px;
	padding-bottom: 40px;
}
.rcs_ringstyle img {
	width: 110px;
	height: 110px;
}
.rcs_ringstyle_content_box_img {
	margin-top: 30px;
}
.rcs_ringstyle.rcs_ringstyle_v2 {
	padding: 20px 0px;
}
.hl_btn_outline_white, .hl_btn_outline_white_bg {
	font-size: 12px;
	padding: 10px 30px;
}
.ddov_eng_ring_text_box p {
	font-size: 14px;
}
.rcs_ringstyle.rcs_ringstyle_v2 .slick-arrow.slick-next {
	width: 30px;
	right: 29%;
	top: 201px;
}
.rcs_ringstyle.rcs_ringstyle_v2 .slick-arrow.slick-prev {
	width: 40px;
	left: 29%;
	top: 201px;
}
}

@media(min-width:992px) and (max-width:1024px){
	.hl_hq_video_box video {
		height: 220px !important;
	}
	.hl_hq_text_box p {
		margin-bottom: 15px;
	}
	.hl_ring_builder_btn_box button {
		font-size: 12px;
		padding: 8px 15px;
		min-width: 180px;
	}
	.rcs_ringstyle img {
		width: 100px !important;
		height: 100px !important;
		object-fit: contain;
	}
	.rcs_hero_slider {
		min-height: 380px !important;
	}
	.rcs_hero_img h1 {
		font-size: 34px !important;
		line-height: 40px !important;
	}
	.rcs_hero_img p {
		font-size: 14px;
		padding: 10px 0 23px !important;
	}
	.rcs_studs_builder {
		height: 270px;
		margin: 30px auto;
	}
	.rcs_studs_builder .ddov_eng_ring_text_box {
		margin-right: 5%;
	}
	.rcs_virtual_appointment_text h2 {
		font-size: 29px;
	}
	.rcs_virtual_appointment_text {
		width: 90% !important;
		margin: 0 auto !important;
	}
	
}
@media (min-width: 1025px) and (max-width: 1150px) {
	.hl_ring_builder_btn_box button {
		font-size: 12px;
		padding: 8px 15px;
	}
	.rcs_studs_builder {
		height: 310px;
	}
	.hl_ring_builder_btn_box {
		justify-content: left !important;
	}
	.rcs_studs_builder .ddov_eng_ring_text_box {
		margin-right: 8% !important;
	}
	.rcs_ringstyle img {
		width: 100px;
		height: 100px;
	}
	}
@media(min-width:1024px) and (max-width:1199px){
	.hl_shap_img_box img {
		width: 80px;
		height: 80px;
	}
	.hl_ring_builder_img video {
		height: 350px !important;
	}
	.rcs_footer_content_one_social_icn_div {
		width: 35px !important;
		height: 35px !important;
		background-color: #fff !important;
	}
	.hl_diamond_headings h2 {
		font-size: 25px;
		margin-bottom: 10px;
	}
	.rcs_footer_content h2 {
		font-size: 20px;
		margin-top: 5px;
	}
	.ddov_eng_ring_text_box h3 {
		font-size: 30px;
		line-height: 40px;
		margin-bottom: 15px;
	}
	.hl_ring_builder_btn_box {
		gap: 10px;
	}
	.ddov_btn_gray, .ddov_btn_outline {
		font-size: 14px;
		line-height: 25px;
		padding: 8px 15px;
	}
	.rcs_ringstyle_content_box h2 {
		font-size: 9px;
	}
	.rcs_ringstyle.rcs_ringstyle_v2 .slick-arrow.slick-prev, .rcs_ringstyle.rcs_ringstyle_v2 .slick-arrow.slick-next {
		width: 40px !important;
		top: 200px;
	}
	.rcs_ringstyle_content_box_img {
		margin-top: 30px;
	}
	.rcs_ringstyle_content_box_img {
		width: 180px !important;
		height: 110px !important;
	}
	.rcs_ringstyle.rcs_ringstyle_v2 {
		padding: 20px 0px;
	}
	.ddov_eng_ring_text_box p {
		font-size: 14px;
	}
	.hl_btn_outline_white, .hl_btn_outline_white_bg {
		font-size: 12px;
		padding: 10px 30px;
	}
	.hl_hq_text_box p {
		font-size: 14px;
		line-height: 22px;
		width: 80%;
	}
	.hl_hq_text_box h2 {
		font-size: 25px;
		font-weight: 600;
		line-height: 40px;
	}
	.hl_hq_text_box h6 {
		font-size: 17px;
		line-height: 30px;
	}
	.hl_hq_video_section {
		padding: 50px 0;
	}
	.hl_hq_video_box {
		text-align: center;
	}
	.hl_hq_video_box img {
		width: 60%;
		margin: 0 auto !important;
	}
	.hl_bestseller_img_text_box h6 {
		font-size: 14px;
		line-height: 22px;
		margin: 5px auto;
	}
	.hl_bestseller_img_text_box p {
		font-size: 14px;
		line-height: 20px;
		margin: 10px auto 10px;
	}
	.hl_bestseller_img_box img {
		width: 70%;
	}
	.hl_diamond_headings p {
		font-size: 14px;
		padding-bottom: 0px;
		margin-bottom: 10px;
	}
	.hl_best_seller_slider .slick-prev, .hl_best_seller_slider .slick-next{
		width: 30px !important;
  height: 30px !important;
	}
	.hl_best_seller_slider .slick-prev{
		left: 0;
	}
	.hl_best_seller_slider .slick-next{
		right: 0;
	}
	.hl_become_An_expert_section {
		padding: 40px 0;
	}
	.hl_btn_outline {
		font-size: 14px;
		padding: 8px 40px;
	}
	.hl_expert_text_img_box h6 {
		font-size: 18px;
		line-height: 20px;
		margin-bottom: 0;
	}
	.hl_expert_text_img_box p {
		font-size: 14px;
		line-height: 22px;
		width: 70%;
		margin: 13px auto;
	}
	.rcs_customer_say_inner .slick-prev {
		left: 80%;
	}
	.rcs_virtual_appointment_text {
		text-align: left;
		width: 90%;
		margin: 0 auto;
	}
	.rcs_virtual_appointment_img img {
		width: 90%;
	}
	.hl_diamond_headings p {
		font-size: 14px;
		margin-bottom: 20px;
	}
	.rcs_signup_to_input_field .input-group button {
		font-size: 12px;
	}
	.rcs_signup_to_input_field .input-group button {
		font-size: 12px;
		padding: 5px 20px;
	}
	.rcs_signup_to_input_field {
		margin-top: 20px;
	}
	section.rcs_signup_to_section {
		padding: 35px 0;
	}
	.ddove_review_user p {
		font-size: 12px;
	}
	.hl_goole_user_img {
		width: 40px;
		height: 40px;
		margin-right: 10px;
	}
	.ddove_review_user {
		padding-left: 7%;
	}
	section.rcs_customer_say_section {
		padding-bottom: 10px;
	}
	section.rcs_color_virtual_section1 {
		padding: 40px 0 20px;
	}
}
@media(min-width:1200px) and (max-width:1440px){
	.rcs_signup_to_input_field .input-group input {
		height: 40px;
	}
	.rcs_signup_to_input_field .input-group input::placeholder{
		font-size: 12px;
	}
	.rcs_signup_to_input_field .input-group button {
		padding: 5px 20px;
		font-size: 12px;
	}
	.rcs_studs_builder {
		height: 310px;
		margin: 40px auto 0;
	}
	.rcs_studs_builder .ddov_eng_ring_text_box {
		margin-right: 11%;
	}
	.hl_hq_video_box video {
		height: 250px !important;
	}
	.hl_ring_builder_img video {
		height: 400px;
	}
	section.rcs_color_virtual_section1 {
		padding: 40px 0 20px;
	}
	section.rcs_customer_say_section {
		padding-bottom: 10px;
	}
	.ddove_review_user {
		padding-left: 7%;
	}
	.hl_goole_user_img {
		width: 40px;
		height: 40px;
		margin-right: 10px;
	}
	.ddove_review_user p {
		font-size: 12px;
	}
	section.rcs_signup_to_section {
		padding: 35px 0;
	}
	.rcs_signup_to_input_field {
		margin-top: 20px;
	}
	.rcs_virtual_appointment_img img {
		width: 90%;
	}
	.rcs_virtual_appointment_text {
		text-align: left;
		width: 75%;
		margin: 0 auto;
	}
	.rcs_virtual_appointment_text p {
		font-size: 14px;
	}
	.hl_expert_text_img_box h6 {
		font-size: 18px;
		line-height: 20px;
		margin-bottom: 0;
	}
	.hl_expert_text_img_box p {
		font-size: 14px;
		line-height: 22px;
		width: 70%;
		margin: 13px auto;
	}
	.hl_become_An_expert_section {
		padding: 40px 0;
	}
	.hl_btn_outline {
		font-size: 12px;
		padding: 8px 40px;
	}
	.hl_best_seller_slider .slick-prev, .hl_best_seller_slider .slick-next ,.rcs_customer_say_inner .slick-prev,.rcs_customer_say_inner .slick-next {
		width: 30px !important;
  height: 30px !important;
	}
	.rcs_customer_say_inner .slick-prev {
		left: 89%;
	}
	.hl_best_seller_slider .slick-prev{
		left: 0;
	}
	.hl_best_seller_slider .slick-next{
		right: 0;
	}
	.hl_bestseller_img_box img {
		width: 70%;
	}
	.hl_bestseller_img_text_box h6 {
		font-size: 14px;
		line-height: 22px;
		margin: 5px auto;
	}
	.hl_bestseller_img_text_box p {
		font-size: 14px;
		line-height: 20px;
		margin: 10px auto 10px;
	}
	.hl_hq_video_box {
		text-align: center;
	}
	.hl_hq_video_box img {
		width: 60%;
		margin: 0 auto !important;
	}
	.hl_hq_video_section {
		padding: 50px 0;
	}
	.hl_hq_text_box h6 {
		font-size: 17px;
		line-height: 30px;
	}
	.hl_hq_text_box h2 {
		font-size: 30px;
		font-weight: 600;
		line-height: 40px;
	}
	.hl_hq_text_box p {
		font-size: 14px;
		line-height: 22px;
		width: 80%;
	}
	.hl_collection_img_box {
		margin: 0 7.5px;
	}
	.rcs_ringbuilder_home {
		padding: 0 20px;
	}
	.ddov_btn_gray, .ddov_btn_outline {
		font-size: 12px !important;
		line-height: 25px;
		padding: 8px 20px !important;
		width: max-content;
	}
	.ddov_eng_ring_text_box h5 {
		font-size: 18px;
		line-height: 20px;
	}
	.ddov_eng_ring_text_box h3 {
		font-size: 30px;
		line-height: 25px;
		margin-bottom: 15px;
	}
	.hl_diamond_headings h2 {
		font-size: 30px;
		line-height: 25px;
		margin-bottom: 15px;
	}
	.hl_diamond_headings p {
		font-size: 14px;
		padding-bottom: 0px;
		margin-bottom: 10px;
	}
	.hl_shap_img_box img {
		width: 65px;
		height: 65px;
	}
	.hl_shap_img_box {
		padding: 4px 0;
	}
	.hl_shap_img_box p {
		font-size: 14px;
		padding: 5px 0;
	}
	section.rcs_ring_style_section {
		padding-top: 40px;
		padding-bottom: 40px;
	}
	.rcs_ringstyle img {
		width: 110px;
		height: 110px;
	}
	.rcs_ringstyle.rcs_ringstyle_v2 .slick-arrow.slick-next, .rcs_ringstyle.rcs_ringstyle_v2 .slick-arrow.slick-prev {
		bottom: 20px;
	}
	.rcs_ringstyle_content_box_img {
		margin-top: 30px;
	}
	.rcs_ringstyle.rcs_ringstyle_v2 {
		padding: 20px 0px;
	}
	.hl_btn_outline_white, .hl_btn_outline_white_bg {
		font-size: 12px;
		padding: 10px 30px;
	}
	.ddov_eng_ring_text_box p {
		font-size: 14px;
	}
}

@media(min-width:1441px) and (max-width:1900px){
	.rcs_studs_builder {
		height: 430px;
		margin: 60px auto 10px;
	}
	.rcs_hero_img p {
		font-size: 16px;
		padding: 15px 0 25px;
	}
	.hl_best_seller_slider .slick-next {
		right: 0;
	}
	.hl_best_seller_slider .slick-prev {
		left: 0;
	}
	.rcs_hero_img h1 {
		font-size: 45px;
		line-height: 50px;
	}
	.hl_bestseller_img_box img {
		width: 70%;
	}
	.hl_bestseller_img_text_box h6 {
		font-size: 14px;
		line-height: 22px;
		margin: 5px auto;
	}
	.hl_bestseller_img_text_box p {
		font-size: 14px;
		line-height: 20px;
		margin: 10px auto 10px;
	}
	.hl_hq_video_box {
		text-align: center;
	}
	.hl_hq_video_box img {
		width: 70%;
		margin: 0 auto !important;
	}
	.hl_hq_video_section {
		padding: 50px 0;
	}
	section.rcs_customer_say_section {
		padding-bottom: 30px;
		padding-top: 50px;
	}
	.rcs_footer_content_one_social_icn_div {
		width: 45px;
		height: 45px;
	}
}

@media(max-width:991px){
	.rcs_studs_builder .rcs_ringbuilder_home {
		align-items: flex-end;
		display: flex;
		height: 100%;
		padding: 0 40px 40px;
		place-content: center;
	}
	.rcs_ringbuilder_home {
		margin-top: 0;
	}
	.ddov_eng_ring_text_box h5 {
		font-size: 20px;
	}
	.rcs_studs_builder .ddov_eng_ring_text_box h3 {
		font-size: 30px;
	}
}