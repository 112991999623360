
@font-face {
    font-family: saintecolombe-light;
    src: url('../font/saintecolombe-light-TRIAL-BF63feb9e0d3ea4.otf');
    font-weight: 300;
  }
@font-face {
    font-family: saintecolombe-regular;
    src: url('../font/saintecolombe-regular-TRIAL-BF63feb9e05a82c.otf');
    font-weight: 300;
  }
@font-face {
    font-family: saintecolombe-medium;
    src: url('../font/saintecolombe-medium-TRIAL-BF63feb9e0d5d6d.otf');
    font-weight: 500;
  }
  @font-face {
    font-family: saintecolombe-bold;
    src: url('../font/saintecolombe-bold-TRIAL-BF63feb9df220ae.otf');
    font-weight: 700;
  }


.hl_new_login_page_section {
	padding: 80px 0;
}
.hl_new_login_page_section .rcs_login div#contained-modal-title-vcenter {
	text-align: left;
	padding: 0 0 32px 0;
	text-transform: unset !important;
	font-weight: 300 !important;
    font-size: 45px;
    color: #1e1e1ef1;
    /* font-family: 'Playfair Display', serif !important; */
    font-family: 'saintecolombe-light' !important;
    /* font-size: 14px; */
}
.hl_new_login_form .form-control, .hl_new_login_form .form-select{
    padding: 20px;
    border-radius: 0;
    font-size: .875rem;
    letter-spacing: .02em;
    padding: 30px 20px;
    background-color: transparent;
    color: #1e1e1e;
}
.hl_new_login_form .form-select {
	border-radius: 0;
	font-size: .875rem;
	letter-spacing: .02em;
	padding: 22px 20px;
	background-color: transparent;
	color: #1e1e1e;
	width: 100%;
	border: 1px solid #ced4da;
    font-size: 14px;
    font-weight: 300;
}
.hl_new_login_form .form-control:focus {
    outline: 0;
    box-shadow: 0px 0px 23px 0px rgb(0 0 0 / 8%);
    border-color:#1e1e1e;
}
.hl_new_login_form .form-control::placeholder {
	color:#1e1e1e9f;
	font-size: 14px;
}
.hl_new_login_form .form-select option {
	color:#1e1e1e9f;
	font-size: 14px !important;
    padding: 0 15px !important;
}

.hl_new_login_form label {
	display: inline-block;
	font-size: .875rem;
	margin-bottom: .75rem;
	font-size: 14px;
	margin-bottom: 12px;
	color: #1e1e1e;
    letter-spacing: .02em;
}
.hl_new_login_form .mb-3 {
    margin-bottom: 24px;
}
.hl_new_login_form button, .hl_new_login_form button:hover{
    text-transform: capitalize !important;
}
.hl_new_login_form button, .hl_new_login_form button:hover{
    text-transform: capitalize !important;
    background-color: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    outline: 0 !important;
}
.rcs_login .hl_new_login_form a {
	padding: 0;
	float: left;
	font-size: 14px;
    color: #1e1e1e;
    margin-bottom: 32px;
}

.hl_new_black_btn:hover, .hl_new_black_btn:active, .hl_new_black_btn:focus {
    outline: 0 !important;
    box-shadow: none !important;
    border-color: #1e1e1e !important;
    background-color: transparent !important;
    color: #1e1e1e !important;
}

.sg_select_state_register{
    padding: 0 15px !important;
} 
.hl_new_white_btn{
	border-radius: 0 !important;
	text-transform: capitalize !important;
	padding: 12.5px 40px !important;
	font-weight: 500 !important;
	font-family: 'Inter', sans-serif !important;
	font-size: 13px !important;
    letter-spacing: 0.125rem !important;
    border: 1px solid  #1e1e1e !important;
    width: 100%;
    text-align: center;
    transition: all .4s ease-in-out;

}
.hl_new_black_btn{
	border-radius: 0 !important;
	text-transform: capitalize !important;
	padding: 12.5px 40px !important;
	font-weight: 500 !important;
	font-family: 'Inter', sans-serif !important;
	font-size: 13px !important;
    letter-spacing: 0.125rem !important;
    border: 1px solid  #1e1e1e !important;
    width: 100%;
    text-align: center;
    color: #fff;
    background-color: #1e1e1e;
    transition: all .4s ease-in-out;
}
.hl_new_black_btn:hover, .hl_new_black_btn:active, .hl_new_black_btn:focus {
	outline: none !important;
	box-shadow: none !important;
    border-color: #1e1e1e !important;
    background-color: transparent !important;
    color: #1e1e1e !important;
    transition: all .4s ease-in-out;
	
}
.hl_new_white_btn:hover, .hl_new_white_btn:active, .hl_new_white_btn:focus {
	outline: none !important;
	box-shadow: none !important;
    border-color: #1e1e1e;
    background-color: #1e1e1e;
    color: #fff !important;
	transition: all .4s ease-in-out;
}
/* holseler */
.hl_new_login_Wholesaler p {
	padding: 0;
	float: left;
	font-size: 14px;
	color: #1e1e1e;
	margin-bottom: 20px;
    letter-spacing: .02em;
    /* font-family: 'saintecolombe-light' !important; */
    font-weight: 300;
}
.Font_saintecolombe{
     font-family: 'saintecolombe-light' !important;
}
.hl_new_login_Wholesaler h6 {
	padding: 0;
	float: left;
	font-size: 25px;
	color: #1e1e1e;
	margin-bottom: 20px;
    letter-spacing: .02em;
    font-weight: 200 !important;
    font-family: 'saintecolombe-light' !important;
}
/* Register style */
.hl_new_register_section .rcs_top_heading h1 {
	font-size: 45px;
	font-weight: 300;
	font-family: 'saintecolombe-light' !important;
	color: #1e1e1e;
	line-height: 50px;
	letter-spacing: .02em;
}
.hl_new_register_section .rcs_top_heading a{
    font-size: 45px;
    font-weight: 300;
    font-family: 'saintecolombe-light' !important;
    font-size: 16px;
    color: #1e1e1e5b;
    margin-bottom: 32px;
    text-decoration: underline; 
    letter-spacing: .02em;
   }
   .white_check_img {
    border-radius: 50%;
    background-color: #000;
    width: 25px;
    height: 25px;
    border: 1px solid #000;
}
/* Responsive style */
@media(min-width:320px) and (max-width:1024px){
    .hl_new_login_form .form-select {
        padding: 16px 20px;
    }
.hl_new_login_page_section .rcs_login div#contained-modal-title-vcenter {
	padding: 0 0 20px 0;
	font-size: 35px;
}
.hl_new_login_form .form-control {
	padding: 25px 15px;
}
.hl_new_black_btn, .hl_new_white_btn {
	padding: 10.5px 40px !important;
	font-size: 12px !important;
}
.hl_new_register_section .rcs_top_heading h1 {
	font-size: 25px;
	line-height: 30px;
}
.hl_new_login_page_section .rcs_top_heading p {
	font-size: 15px;
}
}
@media(min-width:1200px) and (max-width:1440px){
    .hl_new_login_page_section {
        padding: 40px 0;
    }
    .hl_new_login_page_section .rcs_login div#contained-modal-title-vcenter {
        padding: 0 0 22px 0;
        font-size: 35px;
    }
    .hl_new_login_form label {
        font-size: 13px;
        margin-bottom: 10px;
    }
    .hl_new_login_form .form-control {
        padding: 22px 20px;
    }
    .hl_new_login_form .form-select {
        padding: 14px 20px;
    }
    .rcs_login .hl_new_login_form a {
        font-size: 13px;
        margin-bottom: 25px;
    }
    .hl_new_black_btn, .hl_new_white_btn {
        padding: 10px 40px !important;
        font-size: 11px !important;
    }
    .rcs_login {
        padding: 0 40px 40px;
        width: 72%;
        margin: 0 auto;
    }
}