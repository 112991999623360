/* new header hala  */
body{
    overflow-x: hidden;
}
.rcs_currency {
	display: none;
} 
.dfl_loader_diamond_img{
    width: 200px !important;
}
.hala_preloader {
    z-index: 99999;
    background: rgba(0,0,0,0.3);
  }
  .hala_preloader img {
    width: 10%;
  }
.rcs_logo_head {
	background-color: #0A1857;
}
.hl_book_an_appointment_box_outer button {
	font-size: 14px;
	text-transform: uppercase;
	padding: 7px 20px;
	border: 1px solid #fff;
	color: #0A1857;
	background-color: #fff;
	letter-spacing: .06rem;
	margin-left: 10px;
}
.rcs_top_head_content_right.hl_m_content_left {
	display: flex;
	justify-content: start;
}
.rcs_top_head_content_right.hl_m_content_right {
	display: flex;
	justify-content: end;
}
/* --------------------- */
.mobile-menu {
    background: #FFF;
    height: 100%;
    width: 300px;
}
.no_button.accordion-header button:before {
    display: none;
}
.contactDetail.accordion-item button.accordion-button::before {
    opacity: 0;
}

.mobile-menu .auth {
    background-color: #f6f6f6;
    border-radius: 50%;
    margin-right: 15px;
    width: 40px;
    display: block;
    height: 40px;
    text-align: center;
}

.mobile-menu .auth img {
    width: 16px;
    height: 16px;
}

.mobile-menu button {
    background-color: transparent;
    border: none;
    width: 100%;
    padding: 10px;
    position: relative;
}

.mobile-menu button a {
    font-size: 12px;
    color: #000;
    line-height: 40px;
}

.mobile-menu button {
    font-size: 12px;
    text-align: left;
    font-family: 'Nunito Sans' !important;
    text-transform: uppercase;
}

.mobile-menu .accordion .accordion-item:first-child button a {
    display: flex;
    align-items: center;
    letter-spacing: 1px;
}

.mobile-menu .accordion .accordion-item {
    border-bottom: 1px solid #e5e5e5 !important;
}

.mobile-menu .accordion .accordion-item .accordion-header {
    line-height: inherit;
    max-height: fit-content;
    display: flex;
    height: 55px;
    margin: 0;
}

.mobile-menu .accordion .accordion-item .accordion-body {
    box-shadow: inset 0px 11px 8px -10px #ccc !important;
}

/* .mobile-menu .accordion-item .accordion-header button:before {
    content: '\f078';
    font-family: 'FontAwesome';
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #000;
    transition: 0.3s transform ease-in-out;
} */
.mobile-menu .accordion-item .accordion-header button::before {
    background-image: url(../../Assets/images/acc-arrow-down.svg);
    color: #000;
    content: "";
    font-family: FontAwesome;
    height: 1rem;
    margin-left: auto;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform .3s ease-in-out;
    transition: transform .2s ease-in-out;
    width: 1rem;
}
.mobile-menu .accordion-item .accordion-header button:not(.collapsed):before {
    transform: translateY(-50%) rotateZ(180deg);
}

/* .mobile-menu .accordion .accordion-item:first-child button:before,
.mobile-menu .accordion .accordion-item:nth-child(n+7) button:before {
    content: '';
} */

.overlay {
    content: "";
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    position: fixed;
    display: block;
    top: 0px;
    z-index: 999;
    right: 0px;
    height: 100%;
    transition: 0.2s all ease-in-out;
    visibility: hidden;
    opacity: 0;
}

.overlay.open {
    visibility: visible;
    opacity: 1;
}
.rcs_menu_list_item ul li img {
	width: 22px;
	height: auto;
}
.mobile-menu .accordion-body h2.title {
    margin: 8px 0 10px;
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'Nunito Sans' !important;
    padding: 10px 15px 0;
}

.mobile-menu .accordion-body h2.title:nth-child(2n) {
    padding-bottom: 0;
    margin-top: 0;
}

.mobile-menu .accordion-body ul:nth-child(2n) {
    padding-bottom: 0;
}

.mobile-menu .two-col-list {
    column-count: 2;
}

.mobile-menu ul {
    padding: 0px 8px 10px;
}

.mobile-menu ul li a {
	font-size: 12px;
	line-height: normal;
	padding: 4px 0;
	color: #000;
	display: block;
}

.mobile-menu ul li a img {
    width: 27px;
    height: 27px;
    object-fit: contain;
    margin-right: 12px;
}

.mobile-menu .accordion-body {
    background-color: #f6f6f6;
}

.mobile-menu .weddingRings ul li a img {
    width: 40px;
    margin-right: 5px;
}

.mobile-menu .fine_jwel ul li h2 {
    padding: 0 !important;
    margin: 0 !important;
}

.mobile-menu .fine_jwel .accordion-body {
    padding-top: 10px;
}

.mobile-menu .fine_jwel ul li > ul {
    padding: 0 !important;
}

.mobile-menu .contactDetail ul li span {
    background: #666;
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 100%;
    text-align: center;
    line-height: 35px;
    margin: 0 0 5px;
}

.mobile-menu .contactDetail ul li span svg {
    fill: #FFF;
    width: 15px;
    height: 15px;
}

.mobile-menu .contactDetail ul li a {
    text-align: center;
    width: 50px;
}

.mobile-menu .contactDetail ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-menu .contactDetail h2 {
    height: auto !important;
    padding-top: 15px;
}

.mobile-menu .contactDetail h2 button {
    text-align: center;
}

.mobile-menu .accordion .accordion-item:last-child {
    border: none !important;
}

/****** mobile menu css new **/

/* .rcs_top_header_menu_dropdown.mega_menu_content_main {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    top: 70px;
    z-index: 99;
    background: white;
    padding: 20px 30px;
    border-top: 1px solid #f1f1f1;
} */
.mega-list-cat.m-top h2.title14.font-bold.text-uppercase.mtb a {
    color: inherit;
}

.rcs_logo_head.rcs_sticky-top.sticky-top .hover_overlayer{
    top: 100px;
}
.hover_overlayer {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.384);
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 9;
    top: 150px;
    z-index: 9;
}

.rcs_top_header_menu_dropdown.mega_menu_content_main.visible.bw-Engagement-Rings .bw-Shop-by-style .rcs_diamonds_content_inner a img {
    width: 45px !important;
}
.bw-Shop-by-metal a img {
    width: 22px;
}
.mega-list-cat.m-top h2.title14.font-bold.text-uppercase.mtb {
    font-weight: 700 !important;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 8px;
    padding: 0 5px;
    color: #000;
    font-family: 'Santral-Light' !important;
    letter-spacing: .2px;
}
img.engagement_img.gem_img {
    width: 36px;
}
#rcs_menu_header_top ul li:hover h6:before {
    width: 100%!important;
}
.mega-list-cat li a img.img-size1 {
    width: 30px;
    margin-right: 8px;
}
.dfl_ring_stylee_outer_box img {
    width: 25px;
    margin-right: 10px;
}
.color-con.con2 {
    border: 3px solid #ff8080!important;
    background-color: transparent!important;
}
.color-con.con3 {
    border: 3px solid #c9a63a!important;
}
.dfl_fineee_img, .dfl_weddingg_img {
    height: 250px!important;
    object-fit: cover;
}

.color-con.con3, .color-con.con4 {
    background-color: transparent!important;
}
.color-con.con4 {
    border: 3px solid #848484!important;
}
.color-con.con5 {
    border: 3px solid #e5e4e2!important;
    background-color: transparent!important;
    background-color: #e5e4e2;
}
.color-con {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
    display: inline-block;
    border: 1px solid #ddd;
    vertical-align: middle;
}
#rcs_menu_header_top ul li h6::before {
	content: "" !important;
	display: block !important;
	width: 0 !important;
	height: 2px !important;
	transition: width .1s ease !important;
	position: absolute !important;
	left: 0;
	right: 0;
	bottom: -3px;
	color: #212123;
	background-color: #fff;
	margin: 0 auto;
}
.rcs_diamonds_content {
    padding: 20px 20px 0px 20px;
    position: relative;
}

.rcs_diamonds_content_inner p:hover {
    color: black;
}

.dfl_menu_border {
    position: absolute;
    height: 260px;
    top: 0;
    right: 2%;
    border-right: 1px solid #ddd;
}

.cust_padd_diamonds_shape {
    padding: 0 5px;
}

.rcs_diamonds_content h2 a {
    color: inherit;
    font-family: 'Santral-Light';
}

.rcs_diamonds_content ul {
    margin: 0;
    padding: 0;
}

.navReplacement,
.rcs_diamonds_content_inner a {
    display: flex;
    align-items: center;
    margin: 3px 0;
    cursor: pointer;
}

.rcs_diamonds_content_inner img {
    float: left;
    width: 30px;
    line-height: 30px;
    margin: 0 5px 0 0;
}


.rcs_diamonds_content p {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
}

img.img-size {
    width: 30px;
    margin-right: 5px;
    margin-top: -5px;
}

.mega-list-cat ul li a {
    display: block;
    padding: 5px;
    color: inherit;
    font-size: 14px;
}

.dfl_menu_border.shope_by_style,
.rcs_diamonds_content.rcs_engagement.dfl_border_rel .dfl_menu_border {
    right: 0;
}

.rcs_diamonds_content.rcs_engagement.dfL-border_rel {
    float: left;
    width: 100%;
}

.rcs_diamonds_content_inner p {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    color: #505050;
}

.mega-list-cat ul.list-none {
    width: 100%;
    float: left;
    font-size: 13.5px;
}

.rcs_diamonds_content ul {
    margin: 0;
    padding: 0;
}

#rcs_menu_header_top ul li {
    margin: 0;
}

.list-none.dfl_megamenu_engag_list_of_s li {
    width: 50%;
    display: inline-block;
}

.mega-list-cat ul li a {
    display: block;
    padding: 5px;
    color: inherit;
    font-size: 15px;
    color: #505050 !important;
}

.dfl_megamenu_engag_list_of_s.mj_gamestone_drop_item img.engagement_img {
    float: left;
    width: 30px;
    height: 30px;
    margin: 0 5px 4px 0;
}

.engagement_img {
    width: 45px;
    margin-right: 4px;
}

.rcs_diamonds_content h2 {
    font-weight: 700 !important;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 8px;
    padding: 0 5px;
    color: #000;
    font-family: 'Santral-Light' !important;
    letter-spacing: .2px;
}

.rcs_megaMenu_inner {
    background-color: #fffdfd;
    padding: 0;
    overflow: hidden;
    height: 0;
    border-top: 1px solid rgba(122, 118, 118, .2784313725490196);
    padding-bottom: 20px !important;
}

#rcs_menu_header_top ul li:hover h6:after {
    width: 100% !important;
}

#rcs_menu_header_top ul li h6:after {
    content: "" !important;
    display: block !important;
    width: 0 !important;
    height: 2px !important;
    transition: width .1s ease !important;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    color: #212123;
    background-color: #000;
    margin: 0 auto;
}

li.rcs_top_header_menu:hover .rcs_megaMenu {
    display: block;
}


#rcs_menu_header_top ul li:hover .rcs_megaMenu {
    display: block;
}

.rcs_megaMenu {
	/* background-color: rgb(0 0 0 / 20%); */
	background-color: rgba(0, 0, 0, 0.384);
	/* background-color: rgba(240, 237, 237, 0.055); */
	width: 100%;
	height: 100%;
	position: absolute !important;
	/* top: 98px; */
	left: 0;
	right: 0;
	z-index: 99;
	display: none;
}

.rcs_megaMenu_inner {
    background-color: #fffdfd;
    /* margin: 14px; */
    padding: 0px;
    overflow: hidden;
    height: 0;
    border-top: 1px solid #7a767647;
}

.rcs_megaMenu .rcs_megaMenu_inner {
    height: 100%;
}


/*fixed header css for menu*/
.rcs_logo_head.rcs_sticky-top .rcs_megaMenu {
	/* top: 97px; */
}

.mega-list-cat ul li a:hover {
    color: #000000 !important;
}

@media screen and (min-width: 767px) {
    .m-top {
        margin-top: 15px;
    }

}

@media screen and (min-width: 1026px) {
    .mg_main_d {
        display: flex;
        align-items: center;
        justify-content: space-around;
        /* width: 96.5%; */
        margin: 0 auto;
    }
}

@media (min-width: 1200px) and (max-width: 1470px) {
    .rcs_megaMenu {
        /* top: 73px; */
    }
    .rcs_logo_head.rcs_sticky-top.sticky-top .hover_overlayer{
        top: 100px;
    }
    #rcs_menu_header_top ul li h6::before {
        bottom: 1px;
    }
    .hover_overlayer {
        top: 150px;
    }
    .rcs_logo_head.rcs_sticky-top .rcs_megaMenu {
        /* top: 71px; */
    }


    .rcs_logo_head.rcs_sticky-top.sticky-top .rcs_megaMenu_inner .rcs_diamonds_content img {
        max-width: 100%;
    }


}

@media (min-width: 1025px) and (max-width: 1199px) {
    .rcs_right_side ul li {
        padding: 0 0px !important;
        z-index: 999;
    }

    .rcs_megaMenu {
        /* top: 78px; */
    }

    .rcs_logo_head.rcs_sticky-top .rcs_megaMenu {
        /* top: 60px !important; */
    }
}

/*******************************************mega menu css**********************************************/

.rcs_search_bar {
    position: relative;
}


/*Top Header css*/
.rcs_top_head_content {
    position: relative;
    padding: 3px 0;
}

.rcs_top_head_content p {
	text-align: center;
	margin-bottom: 0;
	border-radius: 0;
	padding: 5px;
	font-size: 14px;
	font-family: 'Montserrat', sans-serif !important;
	letter-spacing: 1px;
	font-weight: 500;
    text-transform: uppercase;
}

.rcs_top_head_content p a {
    text-decoration: underline;
    padding-left: 5px;
}

/* .rcs_top_head_content span {
    position: absolute;
    right: 10px;
    top: 0;
    cursor: pointer;
} */
.rcs_mobile_header_menu {
    display: none;
}

.rcs_dropdown_lang span {
    padding-right: 5px;
    font-size: 13px;
    font-weight: 500;
}

.rcs_dropdown_lang button, .rcs_dropdown_lang button:hover, .rcs_dropdown_lang.show button {
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    color: #fff!important;
    padding: 0 !important;
}

.rcs_right_side_mobile ul li a {
    font-size: 12px;
    padding: 0 10px;
    font-weight: 500;
    color: #000;
    text-decoration: none;
}

.rcs_right_side_mobile ul li a img {
    width: 16px;
    margin-right: 5px;
}

.rcs_right_side_mobile ul li .dropdown-menu {
    z-index: 9999;
    min-width: 6rem !important;
}

/*Top Header css*/
/*Header css*/
.rcs_left_side ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.rcs_left_side ul li {
    padding: 0 12px;
    text-transform: uppercase;
    line-height: 30px;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: .075rem;
    padding-left: 0px;
    cursor: pointer;
}

.rcs_left_side ul li i {
    border-radius: 50%;
    font-size: 12px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
}

.rcs_right_side ul {
    display: flex;
    justify-content: end;
    align-items: center;
}

.rcs_right_side ul li {
    padding: 0 5px;
    text-transform: uppercase;
    line-height: 30px;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: .075rem;
    position: relative;
}

.rcs_right_side ul li svg {
	width: 25px;
	height: 25px;
	line-height: 25px;
	text-align: center;
	cursor: pointer;
	color: #ffffffd1 !important;
}

.rcs_wishlist_icon {
	width: 22px;
	cursor: pointer;
	vertical-align: middle;
	margin: 0px 3px;
	filter: grayscale(100%) invert(100%);
}

.rcs_right_side li:nth-child(5) {
    margin-left: 7px;
}

.rcs_right_side li:nth-child(4) img {
    width: 20px;
}

.rcs_right_side li:nth-child(1) img {
    width: 23px;
}

.rcs_header {
    padding: 0px 0px 0px;
    box-shadow: rgb(33 35 38 / 10%) 0px 10px 10px -10px;
}

.rcs_logo_section img {
    width: 240px;
    margin: 10px auto 10px;
    display: block;
    cursor: pointer;
    height: auto;
}
span.rcs_cart_badge,
span.rcs_wish_badge {
    position: absolute;
    top: -4px;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    right: -2px;
    line-height: 20px;
    text-align: center;
    display: block;
}

.rcs_after_login_button {
    text-transform: capitalize;
}

.rcs_search_bar input {
    width: 100%;
    border-radius: 0px;
    font-size: 16px;
}

.rcs_search_bar input:focus {
    box-shadow: none;
    outline: none;
}

.rcs_search_bar input:hover {
    box-shadow: none;
    outline: none;
}

.rcs_search_bar input {
    width: 100%;
}

.rcs_search_bar svg:last-child {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    cursor: pointer;
    margin: auto 0;
}

.rcs_search_bar svg:first-child {
    position: absolute;
    top: 0;
    left: 10px;
    bottom: 0;
    margin: auto 0;
}

.rcs_search_modal .rcs_search_bar {
    position: absolute;
    width: 25%;
    right: 310px;
    z-index: 9;
    top: 80px;
}

.rcs_search_modal12 .rcs_search_bar {
    position: absolute;
    width: 25%;
    right: 310px;
    z-index: 9;
    top: 45px;
}

@media (min-width: 576px) {

    .rcs_search_modal .modal-dialog,
    .rcs_search_modal12 .modal-dialog {
        max-width: 100%;
    }
}

.rcs_search_bar input {
    padding-left: 35px;
    font-size: 14px;
    height: 50px;
    background-color: #fff !important;
}

.rcs_right_side ul li .MuiInputBase-input {
    border-radius: 2px;
}

.rcs_menu {
    position: relative;
    z-index: 998;
}

#rcs_menu_header_top {
    width: 100%;
}

#rcs_menu_header_top ul.rcs_header_menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

#rcs_menu_header_top ul {
    list-style: none;
    /* position:relative;
    float:left;
    margin:0;
    padding:0; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#rcs_menu_header_top ul span a,
#rcs_menu_header_top ul h6 a,
#rcs_menu_header_top ul h6.rcs_search {
    display: block;
    text-decoration: none;
    font-size: 12.5px;
    /* line-height: 32px; */
    padding: 0 20px 20px;
    transition: all 200ms ease-in 50ms !important;
    letter-spacing: .06rem;
    /* font-family: 'Santral-Light' !important; */
    text-transform: uppercase;
    cursor: pointer;
    text-shadow: none;
    margin: 0;
    font-weight: 600;
    font-family: Montserrat, sans-serif !important;
}


#rcs_menu_header_top ul span a {
    text-transform: capitalize;
}

#rcs_menu_header_top ul li {
    position: relative;
    float: left;
    margin: 0;
    padding: 20px 0 0;
}

/* #rcs_menu_header_top ul.rcs_header_menu li:first-child{
    position: unset;
}
#rcs_menu_header_top ul.rcs_header_menu li:nth-child(2){
    position: unset;
} */
#rcs_menu_header_top ul h6 {
    position: relative;
    margin: 0;
}

#rcs_menu_header_top ul li:hover h6::after {
    width: 100% !important;
}

#rcs_menu_header_top ul li h6::after {
	content: '' !important;
	display: block !important;
	width: 0 !important;
	height: 2px !important;
	transition: .1s width ease !important;
	position: absolute;
	left: 0;
	right: 0;
	bottom: -2px;
	color: #212123;
	background-color: #fff;
	margin: 0 auto;
}

#rcs_menu_header_top ul li h6:last-child::after {
    display: none !important;
}

/* #rcs_menu_header_top ul.rcs_header_menu li.rcs_top_header_menu:first-child h6:after,
#rcs_menu_header_top ul.rcs_header_menu li.rcs_top_header_menu:nth-child(2) h6:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #d5d5d5;
    position: absolute;
    top: 33px;
    transform: rotate(-90deg);
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    transition: all .8s;
} */
#rcs_menu_header_top ul ul {
    display: none;
    position: absolute;
    /* top:100%; */
    top: 72px;
    left: 0;
    padding: 5px 0;
    z-index: 999;
}

.mask-overly {
    content: "";
    display: none;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 106px;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 9;
}

#rcs_menu_header_top ul li:hover>.mask-overly {
    display: block !important;
}

.rcs_logo_head.rcs_sticky-top .mask-overly {
    top: 70px;
}

.rsc_mega_menu_ul1 {
    top: 25px !important;
}

#rcs_menu_header_top ul.rcs_header_menu ul.rsc_mega_menu_ul {
    width: 100%;
}

#rcs_menu_header_top ul ul li {
    position: relative;
    float: none;
    width: auto;
    padding: 0 0px;
}

#rcs_menu_header_top ul ul li.rcs_menu_header_top {
    position: relative;
    float: left;
    margin: 0;
    padding: 0
}

#rcs_menu_header_top ul ul span a {
    padding: 10px 15px;
    font-size: 12px;
}

#rcs_menu_header_top ul ul ul {
    top: 0;
    left: 100%
}

#rcs_menu_header_top ul li:hover>ul {
    display: block
}

.MuiAccordionDetails-root.rcs_mobile_inner_menu1 {
    padding: 8px 16px !important;
}

.rcs_logo_head.rcs_sticky-top {
	position: fixed;
	top: -2px;
	z-index: 1020;
	background-color: #0A1857;
	box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
	width: 100%;
}

.rcs_search_modal .modal-content,
.rcs_search_modal12 .modal-content {
    border: none !important;
}

#rcs_menu_header_top ul li:nth-child(5) ul li {
    width: 150px;
}

/*Header css*/
/*Login modal css*/
.rcs_appointment_input.rcs_login button {
    width: 100%;
    padding: 10px 0;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
    border-radius: 0 !important;
}

.rcs_login_modal .modal-header {
    border: none;
}

.rcs_login div#contained-modal-title-vcenter {
    text-align: center;
    padding: 0 0 50px 0;
    text-transform: uppercase;
    font-weight: 600;
}

.rcs_forgot div#contained-modal-title-vcenter {
    padding: 20px 0;
}

.rcs_login {
    padding: 0 40px 40px;
}

.rcs_google_signup_main button {
    justify-content: center;
    margin-top: 15px;
}

.rcs_appointment_input.rcs_login button span {
    line-height: 1;
}

.rcs_login a {
    padding: 0;
    float: right;
    padding-bottom: 7px;
    font-size: 12px;
}

a.rcs_login_forgot.rcs_forogt_pass {
    padding-bottom: 15px;
    font-size: 12px;
    margin-top: -10px;
}

.MuiOutlinedInput-input {
    padding: 13.5px 14px;
}

.rcs_signup_link p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin: 0;
    padding: 40px 0 0;
}

.rcs_signup_link a {
    font-size: 16px;
    font-weight: 600;
    padding: 0 4px;
}

.rcs_check_condition .MuiFormControlLabel-root {
    margin: 0;
    width: 12%;
    float: left;
    justify-content: center;
    align-items: center;
}

.rcs_check_condition {
    margin: 15px 0;
}

.rcs_check_condition span {
    font-size: 12px;
    text-transform: capitalize;
}

.rcs_check_condition span a {
    text-decoration: underline;
    font-weight: 700;
}

.rcs_check_condition .col {
    padding: 0;
}

.rcs_condition {
    position: relative;
    top: 8px;
}

.rcs_appointment_input .MuiFormControl-root.MuiTextField-root {
    width: 100%;
    margin-bottom: 20px;
}

.rcs_forgot_msg {
    text-align: center;
    padding-bottom: 20px;
    font-size: 11px;
    font-weight: 500;
}

.rcs_menu_list_item ul {
    padding: 12px 10px;
}

.rcs_menu_list_item ul li {
    display: block;
    padding: 5px 10px;
}

/*Login modal css*/

/*Register Page Css*/
.rcs_account_content_left {
    padding: 20px;
    border-radius: 3px;
}

.rcs_account_content_left .MuiFormControl-root {
    width: 100%;
    margin-bottom: 15px;
}

.rcs_custom_padding {
    padding: 0 5px !important;
}

.alert.alert-info.text-left {
    margin-top: 20px;
    padding: 20px;
}

.alert.alert-info.text-left span {
    padding-left: 5px;
    margin-top: -2px;
}

/*Register Page Css*/
.rcs_signup_link_error {
    position: relative;
}

.rcs_signup_link_error p {
    position: absolute;
    top: -35px;
    padding: 10px 0 0;
    font-size: 12.5px;
    left: 0;
    right: 0;
}

ul.MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list li {
    display: block !important;
    padding: 10px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    text-align: left !important;
}

/*Mega Menu Css*/
.react-slidedown.my-dropdown-slidedown {
    transition-property: all;
    transition-duration: .3s;
    transition-timing-function: ease-in-out;
}

.rcs_dropdown_menu {
    position: absolute;
    top: 17px;
    left: 0;
    width: 100%;
    animation: rcs_dropdown_menu .7s ease;
    -webkit-animation: rcs_dropdown_menu .7s ease;
    -moz-animation: rcs_dropdown_menu ease .7s;
    -o-animation: rcs_dropdown_menu ease .7s;
    -ms-animation: rcs_dropdown_menu ease .7s;
    transition-property: all;
    transition-duration: .3s;
    transition-timing-function: cubic-bezier(.47, 0, .745, .715);
    z-index: 9;
}

.rcs_dropdown_cotnent {
    display: flex;
    width: 100%;
    padding: 25px 0;
    box-shadow: 1px 1px 3px 0 rgb(100 100 100 / 20%);
}

.rcs_customer_container1.rcs_dropdown_inner_cotnainer.container {
    align-items: unset;
    justify-content: unset;
}

.rcs_customer_container1 {
    width: 100%;
    max-width: 100% !important;
    margin: 0 auto;
    padding: 0 10px !important;
}

.rcs_dropdown_inner_cotnainer {
    width: 100%;
    max-width: 1440px !important;
    margin: 0 auto;
    padding: 0 10px !important;
}

@keyframes rcs_dropdown_menu {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes rcs_dropdown_menu {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes rcs_dropdown_menu {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes rcs_dropdown_menu {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes rcs_dropdown_menu {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.rcs_inner_dropdown_menu h2 {
    font-size: 18px;
    margin: 15px 0 0;
    font-weight: 800;
    cursor: pointer;
}

.rcs_inner_dropdown_menu img {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.rcs_top_header_menu h6 a svg {
    width: 20px;
    height: 20px;
    color: #212123;
    margin-top: -5px;
}

#rcs_menu_header_top ul h6 a span {
    color: transparent;
}

/*Mega Menu Css*/
/*New Header Css*/
/* .rcs_top_head_content_right {
    position: absolute;
    top: 0;
    right: 30px;
} */
.rcs_top_head_content_right ul {
	display: flex;
	align-items: center;
	justify-content: center !important;
}

.rcs_top_head_content_right ul li:first-child {
    padding: 0 10px;
}

.rcs_top_head_content_right ul li span {
    border-radius: 3px;
    padding: 3px 5px 6px;
}

.rcs_top_head_content_right ul li svg {
    width: 16px;
    height: 16px;
    color: #0a1857;
}

.rcs_right_side.rcs_right_side_mobile {
    padding-top: 0px;
}

#rcs_header_seaction {
	border-bottom: 1px solid #0a1857f7;
}

.rcs_top_head_content_right ul li {
    padding: 3px 5px;
    font-size: 14px;
    color: #0a1857;
    cursor: pointer;
    display: flex;
    vertical-align: middle;
    align-items: center;
    text-transform: uppercase;
}

button.rcs_blank_button.rcs_after_login_button.btn.btn-primary {
    border-radius: 50% !important;
    height: 25px;
    width: 25px;
    line-height: 20px;
    padding: 0;
}

button.rcs_blank_button.rcs_after_login_button.btn.btn-primary {
    background-color: #000 !important;
    color: #fff !important;
}

.rcs_blank_button .rcs_mobile svg {
    display: none;
}

/*New Header Css*/


/********** rcs mobile menu contact us css start *********/
.contact-section .rcs_circle {
    MARGIN-TOP: 5PX;
    display: inline-block;
    background: #666 !important;
    width: 38px;
    height: 38px;
    MARGIN-RIGHT: 10PX;
}

.rcs_circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #f1d6d3;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
}

.contact-section .rcs_circle a {
    line-height: 38px;
    color: #fff;
    padding: 0px;
    font-size: 15px;
    border: none;
}

.rcs_extra_link.contact-section.mobile_contact {
    margin-top: 15px;
}

.rcs_circle a p {
    color: #666;
    text-transform: capitalize;
    font-size: 12.5px;
}

.rcs_extra_link p {
    margin-bottom: 5px;
}

/********** rcs mobile menu contact us css end *********/
div#google_translate_element .skiptranslate {
    font-size: 0;
}

div#google_translate_element span {
    display: none;
}

/*bottom css page*/

#rcs_menu_header_top ul li:hover>ul li:nth-first-child(1) {
    padding: 10px 0;
}

#rcs_menu_header_top ul li:hover>ul {
    display: block;
    visibility: visible;
    transition: all .4s cubic-bezier(.2, .06, .05, .95);
    overflow: hidden;
    transform: translateY(0px);
    width: max-content;
    min-width: 100%;
}

#rcs_menu_header_top ul ul {
    display: block;
    position: absolute;
    /* top: 100%; */
    left: 0;
    padding: 0px 0;
    z-index: 999;
    height: auto !important;
    visibility: hidden;
    box-shadow: 0 10px 20px #00000017;
    transform: translateY(-15px);

}

#rcs_menu_header_top ul li:hover>ul li span a {
    transform: translate(0) !important;
    transition: all .4s cubic-bezier(.2, .06, .05, .95) !important;
}

#rcs_menu_header_top ul ul li span a {
    transform: translateY(-18px) !important;
}

#rcs_menu_header_top ul span a,
#rcs_menu_header_top ul h6 a,
#rcs_menu_header_top ul h6.rcs_search {
    transition: none !important;
}

#rcs_menu_header_top ul h6 a {
    height: 85px;
    display: flex;
    align-items: center;
}

#rcs_menu_header_top ul li:hover>ul li:first-child {
    margin-top: 5px !important;
    margin-bottom: 0px !important;
}

/*bottom css page*/


/*Responsive css*/
@media(min-width:320px) and (max-width:767px){
    .rcs_logo_section img {
        width: 150px;
        /* margin: 10px auto; */
        margin: 0 auto;
        display: block;
        padding: 0;
    }
    .dfl_loader_diamond_img{
        width: 100px !important;
    }
}
@media (min-width: 320px) and (max-width: 991px) {
    .rcs_top_head_content_right.hl_m_content_right {
        justify-content: center;
    }
    .rcs_menu {
        display: none;
    }

    /* .rcs_logo_section img {
        width: 140px;
        margin: 10px auto;
        display: block;
        padding: 0;
    } */

    .rcs_right_side_mobile1 {
        position: absolute;
        right: 10px;
        top: 13PX;
    }

    .rcs_left_side ul li a span,
    .rcs_left_side ul li span {
        display: none;
    }

    .rcs_right_side ul li .css-7mt4h1-MuiInputBase-root,
    .rcs_right_side ul li .css-7mt4h1-MuiInputBase-root:focus {
        width: 100% !important;
    }

    .rcs_right_side ul li .MuiInputBase-input {
        width: 100% !important;
    }

    .rcs_right_side_mobile ul li {
        width: 100%;
    }

    .rcs_left_side ul li {
        display: none;
    }

    .rcs_mobile_header_menu {
        display: block;
        position: absolute;
        top: 15px;
        z-index: 999;
        cursor: pointer;
    }

    .rcs_header {
        padding: 5px 0px;
        /* padding-top: 2px; */
    }

    .rcs_top_head_content p {
        font-size: 11px;
    }

    .rcs_mobile_header_menu img {
        width: 23px;
    }

    .rcs_extra_link a {
        padding: 20px 14px;
        font-size: 16px;
        margin-bottom: 0;
        line-height: 1.2;
        width: 100%;
        display: block;
        font-size: 14px !important;
        font-family: 'Santral-Light' !important;
        text-transform: uppercase;
    }

    .rcs_mobile_menu .MuiAccordion-root.Mui-expanded {
        margin: 0;
    }

    .rcs_mobile_menu .MuiPaper-elevation1 {
        box-shadow: none;
        width: 100%;
    }

    .rcs_mobile_menu .MuiAccordionSummary-root.Mui-expanded {
        min-height: 50px !important;
    }

    .rcs_mobile_menu .MuiAccordionSummary-content.Mui-expanded {
        margin: 20px 0;
    }

    .rcs_mobile_inner_menu {
        padding: 0 !important;
    }

    .rcs_mobile_inner_menu .MuiIconButton-edgeEnd {
        margin-right: -11px !important;
    }

    .rcs_mobile_inner_menu .MuiAccordionSummary-root.Mui-expanded {
        border-top: none !important;
        min-height: 50px !important;
    }

    .rcs_mobile_inner_menu .MuiAccordion-root.Mui-expanded {
        margin: 1px 0 !important;
        width: 100%;
    }

    #loginbutton {
        padding: 0 !important;
    }

    .rcs_right_side ul li svg {
        width: 22px;
        height: 22px;
        line-height: 22px;
    }

    .gs_loader1 .MuiCircularProgress-root {
        position: absolute;
        top: 43%;
        left: 43%;
    }

    .rcs_mobile_menu .MuiTypography-body1 {
        cursor: pointer;
        font-size: 14px;
        font-family: 'Santral-Light' !important;
        text-transform: uppercase;
    }

    .MuiAccordionDetails-root.rcs_mobile_inner_menu1,
    .MuiAccordionDetails-root.rcs_mobile_inner_menu1 p {
        font-size: 13px !important;
        font-family: 'Santral-Light' !important;
        text-transform: capitalize;
        padding-left: 10px !important;
    }

    .rcs_account_content_left {
        padding: 10px;
    }

    .rcs_register_content .col-md-4.col-sm-6.col-12 {
        padding: 0;
    }

    .alert.alert-info.text-left span {
        font-size: 12px;
    }

    .rcs_custom_padding {
        padding: 0 5px !important;
    }

    .rcs_philan_content .slick-prev,
    .rcs_philan_content .slick-next {
        display: none !important;
    }

    .rcs_philan_content .rcs_brand_item img {
        margin: 0 auto;
    }

    .rcs_menu_list_item ul li {
        display: block;
        padding: 5px 10px;
        font-size: 14px;
        min-height: 30px;
    }

    .rcs_search_bar input {
        width: 100%;
        margin-left: 0px;
    }

    .rcs_search_bar svg {
        position: absolute;
        right: 10px;
        top: 7px;
    }

    button.rcs_blank_button.rcs_after_login_button.btn.btn-primary {
        border-radius: 50% !important;
        height: 25px;
        width: 25px;
        line-height: 20px;
        padding: 0;
    }

    .rcs_mobile_menu {
        width: 280px;
    }

    .rcs_mobile_inner_menu1 p,
    .rcs_mobile_inner_menu p {
        font-size: 13px !important;
    }

    .rcs_mobile_inner_menu .MuiAccordionSummary-content {
        margin: 0 !important;
    }

    .rcs_mobile_menu .MuiIconButton-root {
        padding: 4px 12px !important;
    }

    .rcs_mobile_menu .MuiAccordionSummary-content {
        border-right: 1px solid #ccc;
        margin: 20px 0;
    }

    .rcs_mobile_menu .MuiAccordionDetails-root {
        display: flex;
        padding: 20px 16px 20px;
    }

    .rcs_mobile_inner_menu .MuiAccordionSummary-root {
        min-height: 28px !important;
    }

}

@media (min-width: 320px) and (max-width: 335px) {
    .rcs_logo_section img {
        width: 130PX;
        margin: 0 auto;
        padding: 0;
    }

    .rcs_right_side ul li {
        padding: 0 1px !important;
    }
}

@media (min-width: 320px) and (max-width: 575px) {
    .rcs_currency {
        display: none;
    }
    .rcs_top_head_content_right ul {
        justify-content: center;
    }
    .rcs_top_head_content_right ul li svg {
        width: 16px;
        height: 16px;
    }
    .rcs_top_head_content_right ul li {
        font-size: 11px;
    }

    .rcs_top_head_content p {
        padding: 5px;
    }

    /*top header css new*/
    /* .rcs_top_head_content_right {
        display: none;
    } */

    .rcs_right_side ul li {
        padding: 0 3px;
    }

    .rcs_right_side li:nth-child(5) {
        margin: 0;
    }

    .rcs_search_modal .rcs_search_bar {
        position: absolute;
        width: 100%;
        right: 0;
        z-index: 9;
        top: 85px;
        left: 0;
    }

    .rcs_search_modal12 .rcs_search_bar {
        position: absolute;
        width: 100%;
        right: 0;
        z-index: 9;
        top: 50px;
        left: 0;
    }

    span.rcs_cart_badge,
    span.rcs_wish_badge {
        top: -4px;
    }

    /*top header css new*/
}

@media (min-width: 576px) and (max-width: 767px) {
    .alert.alert-info.text-left {
        align-items: center;
    }

    .rcs_register_content .col-md-4.col-sm-8.col-12 {
        padding: 0;
    }

    .rcs_search_modal .rcs_search_bar {
        position: absolute;
        width: 300px;
        right: 90px;
        z-index: 9;
        top: 76px;
    }

    .rcs_search_modal12 .rcs_search_bar {
        position: absolute;
        width: 300px;
        right: 90px;
        z-index: 9;
        top: 40px;
    }

    .rcs_top_head_content_right {
        display: none;
    }
}
@media(min-width:768px) and (max-width:1024px){
    .dfl_loader_diamond_img{
        width: 150px !important;
    }
}
@media (min-width: 768px) and (max-width: 991px) {

    .rcs_mobile_header_menu {
        top: 27px !important;
    }
    .rcs_right_side_mobile1 {
        top: 24PX !important;
    }
    .rcs_currency{
		display: none !important;
	}
    .rcs_top_head_content p {
        padding: 0px;
    }

    .rcs_newcontainer {
        width: 100%;
        padding: 0 !important;
    }

    .rcs_custom_padding.col-sm-6.col-12 {
        padding: 0 5px !important;
    }

    .rcs_logo_section img {
        width: 200px;
        margin: 0 auto;
        display: block;
        padding: 0;
    }

    span.rcs_cart_badge,
    span.rcs_wish_badge {
        top: -4px;
    }

    .rcs_search_modal .rcs_search_bar {
        position: absolute;
        width: 300px;
        right: 120px;
        z-index: 9;
        top: 64px;
    }

    .rcs_search_modal12 .rcs_search_bar {
        position: absolute;
        width: 300px;
        right: 120px;
        z-index: 9;
        top: 30px;
    }
}

@media (min-width: 992px) and (max-width: 1024px) {
    .rcs_top_head_content_right ul li {
		font-size: 12px !important;
	}
    .rcs_top_head_content_right.hl_m_content_left {
        display: none !important;
    }
    .mg_menu_right_side_area {
        display: none;
    }
    .rcs_header .col-md-4.col-sm-12 {
        display: none;
    }

    .rcs_top_head_content p {
        padding: 10px;
    }

    .rcs_header .col-lg-4.col-md-12.col-sm-12.col-12 {
        max-width: 100%;
        flex: 100%;
    }

    .rcs_right_side.rcs_right_side_mobile1.d-lg-none {
        display: block !important;
    }

    .rcs_right_side_mobile ul li.d-none.d-lg-block {
        display: none !important;
    }

    .rcs_menu {
        display: none;
    }

    .rcs_logo_section img {
        width: 200px;
        margin: 0 auto;
        display: block;
        padding: 0;
    }

    .rcs_right_side_mobile1 {
        position: absolute;
        right: 10px;
        top: 18px;
    }

    .rcs_left_side ul li a span,
    .rcs_left_side ul li span {
        display: none;
    }

    .rcs_right_side ul li .css-7mt4h1-MuiInputBase-root,
    .rcs_right_side ul li .css-7mt4h1-MuiInputBase-root:focus {
        width: 100% !important;
    }

    .rcs_right_side ul li .MuiInputBase-input {
        width: 100% !important;
    }

    .rcs_right_side_mobile ul li {
        width: 100%;
    }

    .rcs_left_side ul li {
        display: none;
    }

    .rcs_mobile_header_menu {
        display: block;
        position: absolute;
        top: 19px;
        z-index: 999;
        cursor: pointer;
    }

    .rcs_header {
        padding: 10px 0px 10px;
    }

    .rcs_top_head_content p {
        font-size: 14px;
        padding: 0;
        vertical-align: middle;
        padding-bottom: 0;
        line-height: 28px;
    }

    .rcs_mobile_header_menu img {
        width: 27px;
        filter: grayscale(100%) invert(100%);
    }

    .rcs_extra_link a {
        padding: 17px 14px;
        font-size: 16px;
        margin-bottom: 0;
        font-weight: 500;
        line-height: 1.2;
        width: 100%;
        display: block;
    }

    .rcs_mobile_menu .MuiAccordion-root.Mui-expanded {
        margin: 1px 0;
    }

    .rcs_mobile_menu .MuiPaper-elevation1 {
        box-shadow: none;
    }

    .rcs_mobile_menu .MuiAccordionSummary-root.Mui-expanded {
        min-height: 50px !important;
    }

    .rcs_mobile_menu .MuiAccordionSummary-content.Mui-expanded {
        margin: 10px 0;
    }

    .rcs_mobile_inner_menu {
        padding: 0 !important;
    }

    .rcs_mobile_inner_menu .MuiIconButton-edgeEnd {
        margin-right: -37px !important;
    }

    .rcs_mobile_inner_menu .MuiAccordionSummary-root.Mui-expanded {
        border-top: none !important;
        min-height: 50px !important;
    }

    .rcs_mobile_inner_menu .MuiAccordion-root.Mui-expanded {
        margin: 1px 0 !important;
    }

    #loginbutton {
        padding: 0 !important;
        margin-top: -4px !important;
    }

    .gs_loader1 .MuiCircularProgress-root {
        position: absolute;
        top: 43%;
        left: 43%;
    }

    .rcs_mobile_menu .MuiTypography-body1 {
        cursor: pointer;
    }

    .rcs_account_content_left {
        padding: 10px;
    }

    .rcs_register_content .col-md-4.col-sm-6.col-12 {
        padding: 0;
    }

    .alert.alert-info.text-left span {
        font-size: 12px;
    }

    .rcs_custom_padding {
        padding: 0 !important;
    }

    .rcs_search_bar input {
        width: 100%;
        margin-left: 0px;
    }

    button.rcs_blank_button.rcs_after_login_button.btn.btn-primary {
        border-radius: 50% !important;
        height: 25px;
        width: 25px;
        line-height: 20px;
        padding: 0;
    }

    .rcs_large_Tab {
        max-width: 100%;
        flex: 0 0 100%;
    }

    /* .rcs_large_Tab1 {
        display: none;
    } */

    .rcs_mobile_menu .MuiAccordionSummary-content {
        border-right: 1px solid #ccc;
    }

    .rcs_search_modal .rcs_search_bar {
        position: absolute;
        width: 300px;
        right: 120px;
        z-index: 9;
        top: 64px;
    }

    .rcs_search_modal12 .rcs_search_bar {
        position: absolute;
        width: 300px;
        right: 120px;
        z-index: 9;
        top: 30px;
    }
}
@media (min-width: 1025px) and (max-width: 1150px) {
.hl_ring_builder_btn_box {
	justify-content: center;
}
.rcs_hero_slider {
	min-height: 420px;
}
.rcs_hero_img h1 {
	font-size: 35px;
	line-height: 35px;
}
.rcs_diamonds_content_inner p {
	font-size: 10.5px !important;
}
.mega-list-cat ul li a {
	font-size: 10.5px !important;
}
}
@media (min-width: 320px) and (max-width: 1024px) {
    .rcs_right_side_mobile1 ul li a {
        font-size: 12px;
        padding: 0 10px;
        font-weight: 500;
        color: #000;
        text-decoration: none;
    }

    .rcs_right_side_mobile1 ul li a img {
        width: 16px;
        margin-right: 5px;
    }

    .rcs_right_side_mobile1 ul li .dropdown-menu {
        z-index: 9999;
        min-width: 6rem !important;
    }

    .rcs_mobile_currency .rcs_dropdown_lang a {
        font-size: 12px;
        padding: 0 10px;
        font-weight: 500;
        color: #000;
        text-decoration: none;
    }

    .rcs_mobile_currency .rcs_dropdown_lang a img {
        width: 16px;
        margin-right: 5px;
    }

    .rcs_mobile_currency .rcs_dropdown_lang .dropdown-menu {
        z-index: 9999;
        min-width: 6rem !important;
    }

    .rcs_mobile_currency {
        padding: 10px 20px;
        width: 100%;
        /* display: block ruby; */
        display: flow-root;
        border-bottom: 1px solid #e5e5e5;
    }

    .rcs_mobile_currency .rcs_dropdown_lang {
        float: right;
    }
}

@media (min-width: 1025px) and (max-width: 1199px) {
    #rcs_menu_header_top ul ul {
        top: 42px !important;
    }

    .rcs_right_side ul li {
        padding: 0 0px !important;
        z-index: 999;
    }

    .rcs_before_login.btn.btn-link {
        padding: 0.375rem 0.15rem;
    }

    .rcs_before_login.btn.btn-link {
        padding: 0.375rem 0.05rem;
    }

    .rcs_logo_section img {
        width: 300px;
    }

    #rcs_menu_header_top ul span a,
    #rcs_menu_header_top ul h6 a {
        font-size: 11px;
        padding: 0 5px;
    }

    .rcs_left_side ul li a {
        font-size: 8px;
    }

    #rcs_menu_header_top ul ul li {
        width: auto;
    }

    .rcs_testimonial_inner .slick-next,
    .rcs_testimonial_inner .slick-prev,
    .rcs_philan_content .slick-next,
    .rcs_philan_content .slick-prev {
        display: none !important;
    }

    .rcs_newcontainer {
        width: 100%;
        padding: 0;
    }

    .rcs_main_btn2 {
        width: 90%;
        padding: 0 0.25rem;
    }

    .rcs_collection h3 {
        font-size: 24px;
    }

    .rcs_right_side.rcs_right_side_mobile .rcs_blank_button {
        font-size: 12px;
        width: 91px;
        padding: 0;
    }

    .rcs_search_bar svg {
        position: absolute;
        right: 10px;
        top: 7px;
    }

    #rcs_menu_header_top ul h6 a {
        font-size: 9px !important;
        padding: 0 5px 10px !important;
    }
}

@media (min-width: 1300px) and (max-width: 1366px) {
    .rcs_top_head_content_right {
        padding-top: 4px;
    }
}

@media (min-width: 1440px) and (max-width: 1900px) {
    .rcs_custom_home_container.container {
        max-width: 92%;
    }
}
@media (min-width: 1200px) and (max-width: 1440px) {
    .rcs_custom_home_container.container {
        max-width: 92%;
    }
    .rcs_logo_section img {
        width: 160px;
        margin: 5px auto 5px;
    }
    #rcs_menu_header_top ul li {
        padding: 13px 0 0;
    }
    .hl_book_an_appointment_box_outer button {
        font-size: 10px;
        padding: 0px 15px;
    }
    img.engagement_img.gem_img {
        width: 35px;
    }
    
    .rcs_diamonds_content_inner img {
        width: 25px;
    }
    .rcs_diamonds_content.dfL-border_rel.bw-Shop-by-style {
        padding-left: 0;
    }
    .mega-list-cat.m-top h2.title14.font-bold.text-uppercase.mtb {
        font-size: 11px;
    }
    /* .rcs_logo_head.rcs_sticky-top.sticky-top nav#rcs_menu_header_top h6::before {
        bottom: 2px;
    } */
    .rcs_right_side li:nth-child(4) img {
        width: 14px;
    }
    .rcs_wishlist_icon {
        width: 14px;
    }
    .rcs_right_side li:nth-child(1) img {
        width: 14px;
    }
    .bw-Shop-by-metal a img {
        width: 16px;
    }
    .rcs_top_header_menu_dropdown.mega_menu_content_main.visible.bw-Engagement-Rings .bw-Shop-by-style .rcs_diamonds_content_inner a img {
        width: 35px !important;
        height: inherit;
    }
    img.img-size {
        width: 25px;
        margin-top: 0px;
    }

    .dfl_megamenu_engag_list_of_s.mj_gamestone_drop_item img.engagement_img {
        width: 25px;
    }

    .mega-list-cat ul li a {
        padding: 2px 5px;
        font-size: 9px;
    }
    .rcs_diamonds_content.dfL-border_rel.bw-Shop-by-metal {
        padding-left: 0;
    }
    .mega-list-cat ul li a,
    .rcs_diamonds_content p {
        font-size: 10px !important;
    }

    .rcs_diamonds_content h2 {
        font-size: 11px;
    }

    .rcs_engagement {
        padding: 30px 0 0 !important;
    }

    .rcs_engagement a {
        margin: 2px 0;
    }

    /* .rcs_top_head_content ul li {
        color: white;
    } */

    .rcs_top_head_content_right ul li:first-child {
        border-right: 0px solid rgb(255, 255, 255);
        padding: 0 10px;
    }

    .rcs_top_head_content span {
        color: #ffffff;
    }

    #rcs_menu_header_top ul span a, #rcs_menu_header_top ul h6 a, #rcs_menu_header_top ul h6.rcs_search {
        font-size: 12px;
        padding: 0 11px 11px;
    }
    .rcs_shop_by_diamond_title {
        margin-bottom: 2rem;
    }

    #rcs_menu_header_top ul ul li {
        width: auto;
    }

    #rcs_menu_header_top ul li:nth-child(5) ul li {
        width: 110px;
    }

    #rcs_menu_header_top {
        width: 100%;
        margin: 0 auto;
    }

    .rcs_right_side.rcs_right_side_mobile .rcs_blank_button {
        padding: 0;
    }

    .slick-slider.rcs_slider_img .slick-next {
        right: 50px;
    }

    .slick-slider.rcs_slider_img .slick-prev {
        left: 50px;
    }

    .rcs_right_side.rcs_right_side_mobile .rcs_blank_button {
        font-size: 12px;
    }
}

@media (min-width: 1025px) and (max-width: 1340px) {
    .rcs_logo_section img {
        width: 140px;
        margin: 5px auto 5px;
    }

    #rcs_menu_header_top ul h6 a {
        height: 66px;
    }
    .rcs_top_head_content li {
        color: white;
        font-size: 10px;
    }

    #rcs_menu_header_top ul li {
        padding: 12px 5px 0;
    }

    .rcs_right_side ul li {
        padding: 0 4px;
        z-index: 999;
    }

    .rcs_right_side ul li svg {
        width: 20px;
        height: 20px;
        line-height: 20px;
    }

    .rcs_search_modal .rcs_search_bar {
        position: absolute;
        width: 25%;
        right: 230px;
        z-index: 9;
        top: 50px;
    }

    .rcs_search_modal12 .rcs_search_bar {
        position: absolute;
        width: 25%;
        right: 230px;
        z-index: 9;
        top: 20px;
    }

    button.rcs_blank_button.rcs_after_login_button.btn.btn-primary {
        height: 20px;
        width: 20px;
    }

    .rcs_right_side.rcs_right_side_mobile {
        padding-top: 8px;
    }

    #rcs_menu_header_top ul span a,
    #rcs_menu_header_top ul h6 a,
    #rcs_menu_header_top ul h6.rcs_search {
        font-size: 9px;
        padding: 3 10px 5px;
    }

    .rcs_right_side ul {
        display: flex;
        justify-content: end;
        align-items: center;
        margin-top: -7px;
    }

    .mask-overly {
        top: 81px;
    }

    .rcs_logo_head.rcs_sticky-top .mask-overly {
        top: 50px;
    }

    #rcs_menu_header_top ul ul {
        top: 40px;
    }

    #rcs_menu_header_top ul li h6::after {
        bottom: -0.5px;
        height: 0px !important;
    }

    #rcs_menu_header_top ul ul span a {
        padding: 7px 10px;
        font-size: 10px;
    }

    .rcs_right_side_mobile ul li .dropdown-menu {
        transform: translate3d(5px, 23px, 0px) !important;
    }

    .rcs_top_head_content p {
        font-size: 10px;
        padding: 2px 0 0;
    }

    .rcs_top_head_content_right ul li:first-child {
        border-right: 0px solid rgb(255, 255, 255);
        padding: 0 10px;
    }

    .rcs_top_head_content_right ul li {
        font-size: 10px;
    }

    .rcs_top_head_content_right ul li svg {
        width: 12px;
        height: 12px;
    }

    .rcs_top_head_content_right ul li span {
        padding: 0 5px;
    }

    span.rcs_cart_badge,
    span.rcs_wish_badge {
        height: 15px;
        width: 15px;
        line-height: 15px;
        font-size: 10px;
        top: -1px;
    }
}

@media(max-width:1024px){
    .rcs_mobile_header_menu img {
        filter: grayscale(100%) invert(100%);
    }
    
}
@media(min-width:1536px) and (max-width:1550px){
    #rcs_menu_header_top ul h6 a, #rcs_menu_header_top ul h6.rcs_search, #rcs_menu_header_top ul span a {
        font-size: 12px;
    }
    #rcs_menu_header_top ul span a, #rcs_menu_header_top ul h6 a, #rcs_menu_header_top ul h6.rcs_search {
        font-size: 12.5px;
        padding: 0 12px 20px;
    }
    .hl_book_an_appointment_box_outer button {
        font-size: 12px;
        padding: 6px 15px;
    }
}
/* @media (min-width: 1200px) and (max-width: 1340px){ 
    .rcs_right_side.rcs_right_side_mobile .rcs_blank_button {
        font-size: 12px;
        position: absolute;
        left: -74px;
        top: -12px;
        padding: 0;
        width: 91px;
    }
} */
/*Responsive css*/


